import { Component, OnInit, AfterViewInit, AfterViewChecked, TemplateRef, ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

import { UsersRepoService } from 'src/app/repositories/users-repo/users-repo.service';
import { OrganizationsRepoService } from 'src/app/repositories/organizations-repo/organizations-repo.service';
import { DipTabulatorComponent } from 'src/app/shared/dip-tabulator/dip-tabulator.component';
import { PreviousRouteService } from 'src/app/repositories/users-repo/previous-page.service.spec';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit, AfterViewChecked {
  tooltipShowDelay = 400;

  @ViewChild('tabulatorTable', { read: DipTabulatorComponent }) tabulatorTable: DipTabulatorComponent;
  @ViewChild('confirmationDialog', { static: true }) confirmationDialog: TemplateRef<any>;
  @ViewChild('errorDialog', { static: true }) errorDialog: TemplateRef<any>;
  @ViewChild('usersSidenavContainer', { read: MatSidenavContainer }) usersSidenavContainer: MatSidenavContainer;

  @ViewChild('idFilterElement', { read: ElementRef }) idFilterElement: ElementRef;
  @ViewChild('usernameFilterElement', { read: ElementRef }) usernameFilterElement: ElementRef;
  @ViewChild('emailFilterElement', { read: ElementRef }) emailFilterElement: ElementRef;
  @ViewChild('companyNameFilterElement', { read: ElementRef }) companyNameFilterElement: ElementRef;
  @ViewChild('userRoleFilterElement', {read: MatSelect}) userRoleFilterElement: MatSelect;
  @ViewChild('userStatusFilterElement', {read: MatSelect}) userStatusFilterElement: MatSelect;
  @ViewChild('organizationFilterElement', {read: MatSelect}) organizationFilterElement: MatSelect;

  @ViewChild('usersTableContainer', { read: ElementRef }) usersTableContainer: ElementRef;
  private _isUsersTableCreated: boolean = false;
  private _usersTableWrapperHeight = 0;

  isFiltersInputContainerScrolled: boolean = false;

  rowPosition: number = 0;

  userRoleSelectData = [
    {
      id: 'User',
      clientName: 'User',
      checked: false
    },
    {
      id: 'Basic',
      clientName: 'Basic Member',
      checked: false
    },
    {
      id: 'Starter',
      clientName: 'Starter Member',
      checked: false
    },
    {
      id: 'Admin',
      clientName: 'Admin',
      checked: false
    },
    {
      id: 'Pro',
      clientName: 'Professional Member',
      checked: false
    },
  ];

  statusSelectData = [
    {
      id: 'Active',
      clientName: 'Active',
      checked: false
    },
    {
      id: 'Disabled',
      clientName: 'Disabled',
      checked: false
    },
    {
      id: 'Pending',
      clientName: 'Pending',
      checked: false
    },
  ];

  organizationSelectData = [];

  filters: any = {
    id: '',
    username: '',
    email: '',
    companyName: '',
    userRole: '',
    userStatus: '',
    organization: 0
  }

  isIdFilterActive: boolean = false;
  isUsernameFilterActive: boolean = false;
  isEmailFilterActive: boolean = false;
  isCompanyNameFilterActive: boolean = false;
  isUserRoleFilterActive: boolean = false;
  isUserStatusFilterActive: boolean = false;
  isOrganizationFilterActive: boolean = false;

  constructor(
    private _usersRepo: UsersRepoService,
    private _organizationsRepo: OrganizationsRepoService,
    private matDialog: MatDialog,
    private router: Router,
    private previousRouteService: PreviousRouteService,
  ) { }

  ngOnInit(): void {

    const urlArray = this.previousRouteService.getPreviousUrl() ? this.previousRouteService.getPreviousUrl().split('/') : [];

    if (urlArray.length > 2 && urlArray[1] == 'users' && urlArray[2] != '') {
      this.rowPosition = parseInt(urlArray[2]);
    }

    this._organizationsRepo.getOrganizations().subscribe(data => {
      this.organizationSelectData = data.organizations.map((org) => {
        return {
          id: org.id,
          clientName: org.name,
          checked: false
        }
      })
    });

  }

  ngAfterViewInit() {
    this.tabulatorTable.createTable(this.getTableConfig());
    this._isUsersTableCreated = true;
    this._usersTableWrapperHeight = this.usersTableContainer.nativeElement.offsetHeight;
  }

  ngAfterViewChecked() {
    this.usersSidenavContainer.updateContentMargins();

    // this fixes layout of users table
    let height = this.usersTableContainer.nativeElement.offsetHeight;
    if (this._usersTableWrapperHeight !== height) {
      this._usersTableWrapperHeight = height;
      if (this._isUsersTableCreated) {
        this.tabulatorTable.table.redraw(true);
      }
    }
  }

  private getTableConfig() {
    let thisComponent = this;
    let openUserDetailFunction = function (e, row) {
      thisComponent.openUserDetail(row);
    };

    let usersRepo = this._usersRepo;
    let userConfigs = {};

    let ajaxRequestFunc = (url, config, params) => {
      return new Promise(function (resolve, reject) {
        let usersObservable = usersRepo.getUsers();
        usersObservable
          .pipe(catchError(() => {
            reject();
            return observableOf([]);
          }))
          .subscribe(data => {
            resolve(data);

            let configs = null;
            if (data && typeof data === 'object' && data['configs']) {
              configs = data['configs'];
            }
          });
      });
    }

    let tableConfig = {
      height: '100%',
      layout: 'fitColumns', // fit columns to width of table (optional)
      responsiveLayout: true,
      resizableRows: true,
      columns: this.getColumnDefs(userConfigs),
      placeholder: 'No data to display!',
      ajaxURL: usersRepo.getAjaxUrl(),
      ajaxConfig: 'GET',
      ajaxRequestFunc: ajaxRequestFunc,
      ajaxResponse: function (url, params, response) {
        return response.users;
      },
      pagination: 'local',
      paginationSize: 20,
      paginationSizeSelector: true,
      paginationDataReceived: { 'data': 'users' },
      rowClick: openUserDetailFunction,
      rowTap: openUserDetailFunction,
      renderComplete: function () {
        if (
            this.tabulatorTable.table.getPageMax() > 1
            && this.tabulatorTable.table.getPage() == 1
            && this.rowPosition !=0
            ) {
              let row = this.rowPosition;
              this.rowPosition = 0;
              this.tabulatorTable.table.setPageToRow(row)
                .then(function (data) {
                })
                .catch(function (error) {
                });
        }
      }.bind(this)
    };

    return tableConfig;
  }

  private getColumnDefs(configs = null) {
    let thisComponent = this;
    let usersRepo = this._usersRepo;

    let rolesFormatter = function (cell) {
      let cellValue = cell.getValue();
      let formattedValue = '';
      if (Array.isArray(cellValue)) {
        if (cellValue.length > 0) {
          for (let i = 0; i < cellValue.length; i++) {
            if (i == 0) {
              formattedValue += usersRepo.getRoleTitle(cellValue[i]);
            }
            else {
              formattedValue += ' • ' + usersRepo.getRoleTitle(cellValue[i]);
            }
          }
        }
      }

      return formattedValue;
    };

    let actionButtons = function (cell) {
      let rowData = cell.getRow().getData();
      let isActive = typeof rowData.account_status === 'string' && rowData.account_status.toLowerCase() === 'active';

      if (isActive) {
        let btn = '<button class="mat-focus-indicator mat-raised-button mat-button-base mat-primary">';
        btn += '<span class="mat-button-wrapper">Disable</span>';
        btn += '<div class="mat-button-focus-overlay"></div></button>';

        return btn;
      }
      else {
        let btn = '<button class="mat-focus-indicator mat-raised-button mat-button-base mat-accent">';
        btn += '<span class="mat-button-wrapper">Activate</span>';
        btn += '<div class="mat-button-focus-overlay"></div></button>';

        return btn;
      }
    };

    let onActionCellClick = function (event, cell) {
      event.stopPropagation();

      let rowData = cell.getRow().getData();
      let isActive = typeof rowData.account_status === 'string' && rowData.account_status.toLowerCase() === 'active';
      if (isActive) {
        thisComponent.onDisableUserAccount(rowData.id);
      }
      else {
        thisComponent.onActivateUserAccount(rowData.id);
      }
    }

    let columnDefs = [
      { title: "ID", field: "id", headerSort: true, headerSortTristate: true },
      { title: "Username", field: "username", headerSort: true, headerSortTristate: true },
      { title: "First Name", field: "first_name", headerSort: true, headerSortTristate: true },
      { title: "Last Name", field: "last_name", headerSort: true, headerSortTristate: true },
      { title: "Email", field: "email", headerSort: true, headerSortTristate: true },
      { title: "Company Name", field: "company_name", sorter: "string", headerSort: true, headerSortTristate: true },
      { title: "Roles", field: "roles", headerSort: false, headerSortTristate: true, formatter: rolesFormatter },
      { title: "Account Status", field: "account_status", headerSort: true, headerSortTristate: true },
      { title: "Organization", field: "organization.id", headerSort: true, headerSortTristate: true, visible: false},
      { title: "Actions", field: 'actions', formatter: actionButtons, cellClick: onActionCellClick }
    ];

    return columnDefs;
  }

  applyFilter(event: any, type = '') {
    let filterChanged: boolean = false;
    let newValue = this.usernameFilterElement.nativeElement.value.trim().toLowerCase();

    newValue = this.idFilterElement.nativeElement.value.trim().toLowerCase();
    if (this.filters.id !== newValue) {
      this.filters.id = newValue;
      filterChanged = true;
    }

    newValue = this.usernameFilterElement.nativeElement.value.trim().toLowerCase();
    if (this.filters.username !== newValue) {
      this.filters.username = newValue;
      filterChanged = true;
    }

    newValue = this.emailFilterElement.nativeElement.value.trim().toLowerCase();
    if (this.filters.email !== newValue) {
      this.filters.email = newValue;
      filterChanged = true;
    }

    newValue = this.companyNameFilterElement.nativeElement.value.trim().toLowerCase();
    if (this.filters.companyName !== newValue) {
      this.filters.companyName = newValue;
      filterChanged = true;
    }

    if (type === 'selectUserRole') {
      newValue = event.value;
      if (this.filters.userRole !== newValue) {
        this.filters.userRole = newValue;
        filterChanged = true;
      }
    }

    if (type === 'selectUserStatus') {
      newValue = event.value;
      if (this.filters.userStatus !== newValue) {
        this.filters.userStatus = newValue;
        filterChanged = true;
      }
    }

    if (type === 'selectOrganization') {
      newValue = event.value;
      if (this.filters.organization !== newValue) {
        this.filters.organization = newValue;
        filterChanged = true;
      }
    }

    if (filterChanged) {
      this.setActiveStates();
      let tabFilters = this.buildFiltersQuery();
      this.tabulatorTable.setFilter(tabFilters);
    }

  }

  private setActiveStates() {
    if (this.filters.id == '') {
      this.isIdFilterActive = false;
    } else {
      this.isIdFilterActive = true;
    }

    if (this.filters.username == '') {
      this.isUsernameFilterActive = false;
    } else {
      this.isUsernameFilterActive = true;
    }

    if (this.filters.email == '') {
      this.isEmailFilterActive = false;
    } else {
      this.isEmailFilterActive = true;
    }

    if (this.filters.companyName == '') {
      this.isCompanyNameFilterActive = false;
    } else {
      this.isCompanyNameFilterActive = true;
    }

    if (this.filters.userRole == '') {
      this.isUserRoleFilterActive = false;
    } else {
      this.isUserRoleFilterActive = true;
    }

    if (this.filters.userStatus == '') {
      this.isUserStatusFilterActive = false;
    } else {
      this.isUserStatusFilterActive = true;
    }

    if (this.filters.organization == 0) {
      this.isOrganizationFilterActive = false;
    } else {
      this.isOrganizationFilterActive = true;
    }
  }

  private buildFiltersQuery() {
    let filtersQuery = [];

    if (typeof this.filters.id === 'string' && this.filters.id.length > 0) {
      let filter = {
        'field': 'id',
        'type': '=',
        'value': this.filters.id
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.username === 'string' && this.filters.username.length > 0) {
      let filter = {
        'field': 'username',
        'type': 'like',
        'value': this.filters.username
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.email === 'string' && this.filters.email.length > 0) {
      let filter = {
        'field': 'email',
        'type': 'like',
        'value': this.filters.email
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.companyName === 'string' && this.filters.companyName.length > 0) {
      let filter = {
        'field': 'company_name',
        'type': 'like',
        'value': this.filters.companyName
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.userRole === 'string' && this.filters.userRole.length > 0) {
      let filter = {
        'field': 'roles',
        'type': 'like',
        'value': this.filters.userRole
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.userStatus === 'string' && this.filters.userStatus.length > 0) {
      let filter = {
        'field': 'account_status',
        'type': 'like',
        'value': this.filters.userStatus
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.organization === 'number' && this.filters.organization > 0) {

      let filter = {
        'field': 'organization.id',
        'type': '=',
        'value': this.filters.organization
      };

      filtersQuery.push(filter);
    }

    return filtersQuery;
  }

  clearFilters(event: Event) {
    let isFilterChanged: boolean = false;
    let newValue = '';

    if (this.filters.id !== newValue) {
      this.filters.id = newValue;
      isFilterChanged = true;
    }
    this.idFilterElement.nativeElement.value = '';

    if (this.filters.username !== newValue) {
      this.filters.username = newValue;
      isFilterChanged = true;
    }
    this.usernameFilterElement.nativeElement.value = '';

    if (this.filters.email !== newValue) {
      this.filters.email = newValue;
      isFilterChanged = true;
    }
    this.emailFilterElement.nativeElement.value = '';

    if (this.filters.companyName !== newValue) {
      this.filters.companyName = newValue;
      isFilterChanged = true;
    }
    this.companyNameFilterElement.nativeElement.value = '';

    if (this.filters.userRole !== newValue) {
      this.filters.userRole = newValue;
      isFilterChanged = true;
    }
    this.userRoleFilterElement.options.forEach((data: MatOption) => data.deselect());
    this.userRoleFilterElement.value = null;

    if (this.filters.userStatus !== newValue) {
      this.filters.userStatus = newValue;
      isFilterChanged = true;
    }
    this.userStatusFilterElement.options.forEach((data: MatOption) => data.deselect());
    this.userStatusFilterElement.value = null;

    if (this.filters.organization !== newValue) {
      this.filters.organization = newValue;
      isFilterChanged = true;
    }

    this.organizationFilterElement.options.forEach((data: MatOption) => data.deselect());
    this.organizationFilterElement.value = null;

    if (isFilterChanged) {
      this.setActiveStates();
      this.tabulatorTable.setFilter([]);
    }

  }

  onScroll(event) {
    if (event.target.scrollTop > 2) {
      this.isFiltersInputContainerScrolled = true;
    } else {
      this.isFiltersInputContainerScrolled = false;
    }
  }

  openUserDetail(row) {
    let id = row.getData().id;
    this.router.navigate(['users/' + id]);
  }

  onActivateUserAccount(id) {
    let config = {
      data: {
        id: id,
        msg: 'Are you sure you want to activate user account with ID ' + id + '?'
      }
    };

    const dialogRef = this.matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._usersRepo.activateAccount({ id: id })
          .pipe(catchError(() => {
            return observableOf(false);
          }))
          .subscribe(data => {
            if (data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
              let user = data['user'];
              user['actions'] = '';
              let partialData = [user];
              this.tabulatorTable.table.updateData(partialData);
            }
            else {
              let config = {
                data: {
                  msg: 'Failed to activate user account!'
                }
              };

              this.matDialog.open(this.errorDialog, config);
            }
          });
      }
    });
  }

  onDisableUserAccount(id) {
    let config = {
      data: {
        id: id,
        msg: 'Are you sure you want to disable user account with ID ' + id + '?'
      }
    };

    const dialogRef = this.matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._usersRepo.disableAccount({ id: id })
          .pipe(catchError(() => {
            return observableOf(false);
          }))
          .subscribe(data => {
            if (data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
              let user = data['user'];
              user['actions'] = '';
              let partialData = [user];
              this.tabulatorTable.table.updateData(partialData);
            }
            else {
              let config = {
                data: {
                  msg: 'Failed to disable user account!'
                }
              };

              this.matDialog.open(this.errorDialog, config);
            }
          });
      }
    });
  }
}
