<div class="loading-shade" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div class="part-request-page-wrapper">
  <div class="part-request-action-buttons-wrapper">
    <button *ngIf="isAuthenticated" mat-raised-button color="primary" (click)="onMakeNewPartRequest($event)">New Part Request</button>
    <mat-icon id="info-icon" class="info-button" (click)="onMoreInfo()">info</mat-icon>
  </div>
  <div #partRequestsTableWrapper class="part-request-table-wrapper">
    <app-dip-tabulator #partRequestsTable>
    </app-dip-tabulator>
  </div>
</div>
