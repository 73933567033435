import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/service/shared.service';

import { PartRequestsRepoService } from 'src/app/repositories/part-requests-repo/part-requests-repo.service';
import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Component({
  selector: 'app-part-requests-form-dialog',
  templateUrl: './part-requests-form-dialog.component.html',
  styleUrls: ['./part-requests-form-dialog.component.scss']
})
export class PartRequestsFormDialogComponent implements OnInit {
  isLoading: boolean = false;

  private _errors = [];
  get errors() { return this._errors };

  canEdit = false;
  isAuthenticated = false;
  isAdmin = false;

  partRequestId = 0;

  parentRequestId = 0;

  partRequestForm = null;

  autoCompleteArray = [];

  dataTypeSelectData = [
    {
      id: 'Heavy ion SEE',
      clientName: 'Heavy ion SEE',
      checked: false
    },
    {
      id: 'Gamma ray TID',
      clientName: 'Gamma ray TID',
      checked: false
    },
    {
      id: 'Proton',
      clientName: 'Proton',
      checked: false
    },
    {
      id: 'Neutron',
      clientName: 'Neutron',
      checked: false
    },
    {
      id: 'Other',
      clientName: 'Other',
      checked: false
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) private _dialogData,
    private _securityRepoService: SecurityRepoService,
    private _dialogRef: MatDialogRef<PartRequestsFormDialogComponent>,
    private _partRequestsRepo: PartRequestsRepoService,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    let defaultValues = {
      partNumber: '',
      manufacturer: '',
      function: '',
      dataType: [],
      notes: ''
    };
    this.isAuthenticated = this._securityRepoService.isAuthenticated;

    if(this._dialogData && this._dialogData.defaultValues) {

      if (
        this._securityRepoService.isAdmin ||
        (
          this._securityRepoService.user &&
          this._dialogData.defaultValues.user_id == this._securityRepoService.user.id
        )
      ) {
        this.canEdit = true;
      }

      this.isAdmin = this._securityRepoService.isAdmin;

      if(typeof this._dialogData.defaultValues.partNumber === 'string') {
        defaultValues.partNumber = this._dialogData.defaultValues.partNumber;
      }

      if(typeof this._dialogData.defaultValues.manufacturer === 'string') {
        defaultValues.manufacturer = this._dialogData.defaultValues.manufacturer;
      }

      if(typeof this._dialogData.defaultValues.function === 'string') {
        defaultValues.function = this._dialogData.defaultValues.function;
      }

      if(typeof this._dialogData.defaultValues.dataType === 'string') {
        defaultValues.dataType = this._dialogData.defaultValues.dataType.split(', ');
      }

      if(typeof this._dialogData.defaultValues.notes === 'string') {
        defaultValues.notes = this._dialogData.defaultValues.notes;
      }

      if(this._dialogData.defaultValues.partRequestId !== 0) {
        this.partRequestId = this._dialogData.defaultValues.partRequestId;
      }

      if(this._dialogData.defaultValues.parentRequestId !== 0) {
        this.parentRequestId = this._dialogData.defaultValues.parentRequestId;
      }

    }

    this.partRequestForm = this._formBuilder.group({
      partNumber: [defaultValues.partNumber, [Validators.required]],
      manufacturer: [defaultValues.manufacturer],
      function: [defaultValues.function],
      dataType: [defaultValues.dataType],
      notes: [defaultValues.notes]
    });
  }

  getPartNumberError() {
    let fieldControl = this.partRequestForm.get('partNumber');
    if(fieldControl && fieldControl.hasError('required')) {
      return 'Part Number is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return 'Unknown error';
  }

  getAutoComplete(event, type) {
    let queryString = event.target.value;

    if (queryString.length >= 3) {
      this._partRequestsRepo.getAutocomplete({ 'column': type, 'data': queryString }).subscribe(data => {
        this.autoCompleteArray = data;
      });
    }
  }

  clearAutoComplete() {
      this.autoCompleteArray = [];
  }

  onSubmitPartRequest(isEdit = false) {
    this._errors = [];
    this.isLoading = true;

    let value = this.partRequestForm.value;
    value.dataType = value.dataType.join(', ');
    value.id = this.partRequestId;
    value.parent_id = this.parentRequestId;

    this._partRequestsRepo.createEditPartRequest(value, isEdit)
    .pipe(catchError((err) => {
      this.isLoading = false;
      let ajaxResult = {
        errors: {
          detail: 'Failed to process part request!'
        }
      };

      return observableOf(ajaxResult);
    }))
    .subscribe(responseData => {
      this.isLoading = false;
      let hasResult = responseData && typeof responseData === 'object';
      if(hasResult && responseData['isSuccess'] === true) {
        this.partRequestForm.reset();
        this._dialogRef.close(true);
        this._snackBar.open('Your part request is submitted!', 'ok', { duration: 7000 });
        this.sharedService.emitEvent('request_added');

        return;
      }

      let hasErrors = hasResult && responseData['errors'] && typeof responseData['errors'] === 'object';
      let errors = hasErrors ? responseData['errors'] : {};
      let errorMessage = 'Failed to process part request!';
      if(typeof errors['detail'] === 'string') {
        errorMessage = errors['detail'];
      }

      let hasValidationErrors = typeof errors['validationErrors'] === 'object' && errors['validationErrors'];
      if(hasValidationErrors && Array.isArray(errors['validationErrors']['violations'])) {
        for(let violation of errors['validationErrors']['violations']) {
          let title = 'Unknown error';
          if(violation['title'] && typeof violation['title'] === 'string') {
            title = violation['title'];
          }

          let filedControl = this.getControlByName(violation['propertyPath']);
          if(filedControl) {
            filedControl.setErrors({apiError: title});
            filedControl.markAsTouched();
          }
        }
      }

      this._errors.push(errorMessage);
    });
  }

  private getControlByName(name) {
    switch(name) {
      case 'part_number':
      case 'partNumber':
          return this.partRequestForm.get('partNumber');
      case 'manufacturer':
          return this.partRequestForm.get('manufacturer');
      case 'function':
          return this.partRequestForm.get('function');
      case 'data_type':
      case 'dataType':
          return this.partRequestForm.get('dataType');
      case 'notes':
          return this.partRequestForm.get('notes');
    }

    return null;
  }
}
