import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DipUserModel } from '../../models/dip-user.model';
import { DipCookieService } from 'src/app/shared/dip-cookie/dip-cookie.service';
import { htmlAstToRender3Ast } from '@angular/compiler/src/render3/r3_template_transform';

@Injectable({
  providedIn: 'root'
})
export class SecurityRepoService {
  static readonly urls = {
    signup: environment.apiUrl + 'signup',
    login: environment.apiUrl + 'login',
    logout: environment.apiUrl + 'logout',
    isAuthenticated: environment.apiUrl + 'is-authenticated',
    userProfile: environment.apiUrl + 'user-profile',
    changePassword: environment.apiUrl + 'user-profile/change-password',
    requestPasswordReset: environment.apiUrl + 'password-reset-request',
    resetPassword: environment.apiUrl + 'reset-password'
  };

  private _user: DipUserModel = null;
  get user() { return this._user; };

  private _isAuthenticated: boolean = false;
  get isAuthenticated() { return this._isAuthenticated; };

  get isAdmin() {
    if(this._user && Array.isArray(this._user.roles)) {
      for(let i = 0; i < this._user.roles.length; i++) {
        if(this._user.roles[i] === 'ROLE_ADMIN') {
          return true
        }
      }
    }

    return false;
  };

  private userChangedSource = new Subject<DipUserModel>();
  userChanged$ = this.userChangedSource.asObservable();

  private authenticationChangedSource = new Subject<boolean>();
  authenticationChanged$ = this.authenticationChangedSource.asObservable();

  redirectUrl: string = '/';

  constructor(
    private _router: Router,
    private _httpClient: HttpClient,
    private _cookieService: DipCookieService
  )
  {
    let user = _cookieService.getCookie('dip_user');
    if(user) {
      this.setUser(this.parseCookieUser(user));
    }
  }

  login(credentials): Observable<any> {
    let content = JSON.stringify(credentials);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let loginObservable = this._httpClient.post<any>(SecurityRepoService.urls.login, content, options)
    .pipe(
      map((response) => {
        if(response && response.user) {
          this.setUser(this.parseResponseUser(response.user));
          return true;
        }

        this.setUser(null);
        return false;
      }),
      catchError(() => {
        this.setUser(null);
        return observableOf(false);
      })
    );

    return loginObservable;
  }

  logout(): Observable<any> {
    let logoutObservable = this._httpClient.get<any>(SecurityRepoService.urls.logout)
    .pipe(
      map((response) => {
        this.setUser(null);
        return true;
      }),
      catchError(() => {
        return this.logoutHelper();  // this will double check if user is logged in
      })
    );

    return logoutObservable;
  }

  private logoutHelper(): Observable<any> {  // will double check if user is logged in
    let logoutObservable = this._httpClient.get<any>(SecurityRepoService.urls.isAuthenticated)
    .pipe(
      map((response) => {
        if(response && typeof response === 'object' && response['isAuthenticated'] === false) {
          this.setUser(null);
          return true;
        }

        return false;
      }),
      catchError(() => {
        return observableOf(false);
      })
    );

    return logoutObservable;
  }

  getUserProfile(): Observable<any> {
    return this._httpClient.get<any>(SecurityRepoService.urls.userProfile);
  }

  updateUserProfile(formValues): Observable<any> {
    let content = JSON.stringify(formValues);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(SecurityRepoService.urls.userProfile, content, options);
  }

  changePassword(formValues): Observable<any> {
    let content = JSON.stringify(formValues);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(SecurityRepoService.urls.changePassword, content, options);
  }

  requestPasswordReset(formValues): Observable<any> {
    let content = JSON.stringify(formValues);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(SecurityRepoService.urls.requestPasswordReset, content, options);
  }

  resetPassword(formValues): Observable<any> {
    let content = JSON.stringify(formValues);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(SecurityRepoService.urls.resetPassword, content, options);
  }

  private setUser(user: DipUserModel) {
    let isAuthenticated: boolean = false;
    if(!user || typeof user.username !== 'string' || user.username.length < 1) {
      this._user = null;
    }
    else {
      isAuthenticated = true;
      this._user = user;
    }

    this._cookieService.setCookie('dip_user', this._user);
    this.userChangedSource.next(this._user);
    this.setAuthenticated(isAuthenticated);
  }

  private setAuthenticated(isAuthenticated: boolean) {
    let changed = this._isAuthenticated !== isAuthenticated;
    this._isAuthenticated = isAuthenticated;

    if(changed) {
      this.authenticationChangedSource.next(isAuthenticated);
    }
  }

  private parseResponseUser(user): DipUserModel {
    if(!user) {
      return null;
    }

    let dipUser: DipUserModel = {
      id: typeof user.id === 'number' ? user.id : 0,
      username: typeof user.username === 'string' ? user.username : '',
      firstName: typeof user.first_name === 'string' ? user.first_name : '',
      lastName: typeof user.last_name === 'string' ? user.last_name : '',
      roles: Array.isArray(user.roles) ? user.roles : [],
      configs: typeof user.configs === 'object' ? user.configs : null,
      ip: typeof user.ip === 'string' ? user.ip : ''
    };

    return dipUser;
  }

  private parseCookieUser(userStr): DipUserModel {
    if(typeof userStr !== 'string' || userStr.length < 1) {
      return null;
    }

    let user = null;
    try {
      user = JSON.parse(userStr);
    }
    catch(err) {
      return null;
    }

    if(!user) {
      return null;
    }

    let dipUser: DipUserModel = {
      id: typeof user.id === 'number' ? user.id : 0,
      username: typeof user.username === 'string' ? user.username : '',
      firstName: typeof user.firstName === 'string' ? user.firstName : '',
      lastName: typeof user.lastName === 'string' ? user.lastName : '',
      roles: Array.isArray(user.roles) ? user.roles : [],
      configs: typeof user.configs === 'object' ? user.configs : null,
      ip: typeof user.ip === 'string' ? user.ip : ''
    };

    return dipUser;
  }

  signup(formValues): Observable<any> {
    let content = JSON.stringify(formValues);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(SecurityRepoService.urls.signup, content, options);
  }

  refreshAuthStatus(): void {
    this._httpClient.get<any>(SecurityRepoService.urls.isAuthenticated)
    .subscribe(response => {
      if(response && typeof response === 'object') {
        let wasAuthenticated = this.isAuthenticated;
        if(response['isAuthenticated'] === false) {
          this.setUser(null);
          if(wasAuthenticated) {
            this._router.navigate(['/login']);
          }

          return;
        }

        if(response['isAuthenticated'] === true) {
          this.setUser(this.parseResponseUser(response.user));
          return;
        }
      }
    });
  }

  logEntry(): void {
    if (this._user) {
      let href = environment.apiUrl + 'log-entry';
      let params = {
        username: this._user.username
      }

      let content = JSON.stringify(params);
      let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
      };

      this._httpClient.post(href, content, options).subscribe();
    }
  }

  logExit() {
    if (this._user) {
      let href = environment.apiUrl + 'log-exit';
      let params = {
        username: this._user.username
      }

      let content = JSON.stringify(params);
      let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
      };

      this._httpClient.post(href, content, options).subscribe();
    }
  }

  logCopy(text:string) {
    if (this._user) {
      let href = environment.apiUrl + 'log-copy';
      let params = {
        username: this._user.username,
        text: text
      }

      let content = JSON.stringify(params);
      let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
      };

      this._httpClient.post(href, content, options).subscribe();
    }
  }

  alertAdmin(params) {
    let href = environment.apiUrl + 'alert';

    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this._httpClient.post(href, content, options).subscribe()
  }
}
