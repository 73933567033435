import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  isLoading: boolean = false;
  isEditMode: boolean = false;

  private _errors = [];
  get errors() { return this._errors };

  private _userProfile = null;
  get userProfile() { return this._userProfile };

  membershipType = null;
  userRoles = null;

  private formFieldValidators = {
    firstName: [Validators.required],
    lastName: [Validators.required],
    companyName: null
  };

  userProfileForm = this._formBuilder.group({
    username: [''],
    firstName: [''],
    lastName: [''],
    email: [''],
    companyName: ['']
  });

  @ViewChild('firstNameInput', {read: MatInput}) firstNameInput: MatInput;

  constructor(
    private _formBuilder: FormBuilder,
    private _securityRepo: SecurityRepoService,
    private _matDialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.loadUserProfile();
  }

  getFirstNameError() {
    let fieldControl = this.userProfileForm.get('firstName');
    if(fieldControl.hasError('required')) {
      return 'First name is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getLastNameError() {
    let fieldControl = this.userProfileForm.get('lastName');
    if(fieldControl.hasError('required')) {
      return 'Last name is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getEmailError() {
    let fieldControl = this.userProfileForm.get('email');
    if(fieldControl.hasError('required')) {
      return 'Email is required';
    }

    if(fieldControl.hasError('email')) {
      return 'Not a valid email';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getCompanyNameError() {
    let fieldControl = this.userProfileForm.get('companyName');
    if(fieldControl.hasError('required')) {
      return 'Company name is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getPasswordError() {
    let fieldControl = this.userProfileForm.get('password');
    if(fieldControl.hasError('required')) {
      return 'Password is required';
    }

    if(fieldControl.hasError('minlength')) {
      return 'Password must be at least ' + fieldControl.errors.minlength.requiredLength + ' charcters';
    }

    if(fieldControl.hasError('maxlength')) {
      return 'Password cannot be longer than ' + fieldControl.errors.maxlength.requiredLength + ' charcters';
    }

    if(fieldControl.hasError('pattern')) {
      return 'Password contains invalid charcters. Allowed characters are a-z, A-Z, 0-9, and the following special characters: !@#$%^&';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  private loadUserProfile() {
    this._errors = [];
    this.isLoading = true;
    this._securityRepo.getUserProfile()
    .pipe(catchError(() => {
      this.isLoading = false;
      return observableOf(false);
    }))
    .subscribe(result => {
      this.isLoading = false;
      if(result && typeof result === 'object' && result['userProfile']) {
        this._errors = [];
        this._userProfile = this.parseUserProfile(result['userProfile']);
        this.resetUserProfileForm();
      }
      else {
        this._userProfile = null;
        this.errors.push('Failed to load user profile!');
      }
    });
  }

  private resetUserProfileForm() {
    this.userProfileForm.reset();

    this.userProfileForm.get('username').setValue(this._userProfile['username']);
    this.userProfileForm.get('firstName').setValue(this._userProfile['firstName']);
    this.userProfileForm.get('lastName').setValue(this._userProfile['lastName']);
    this.userProfileForm.get('email').setValue(this._userProfile['email']);
    this.userProfileForm.get('companyName').setValue(this._userProfile['companyName']);
  }

  private parseUserProfile(userProfile) {
    if(!userProfile || typeof userProfile !== 'object') {
      userProfile = {};
    }

    let parsedUserProfile = {};
    parsedUserProfile['username']     = typeof userProfile['username']     === 'string' ? userProfile['username']     : '';
    parsedUserProfile['firstName']   = typeof userProfile['first_name']   === 'string' ? userProfile['first_name']   : '';
    parsedUserProfile['lastName']    = typeof userProfile['last_name']    === 'string' ? userProfile['last_name']    : '';
    parsedUserProfile['email']        = typeof userProfile['email']        === 'string' ? userProfile['email']        : '';
    parsedUserProfile['companyName'] = typeof userProfile['company_name'] === 'string' ? userProfile['company_name'] : '';

    let isInternal = false;
    let isMember = false;
    let roles = [];
    let membershipType = null;
    if(Array.isArray(userProfile.roles)) {
      for(let i = 0; i < userProfile.roles.length; i++) {
        if(typeof userProfile.roles[i] === 'string') {
          switch(userProfile.roles[i]) {
            case 'ROLE_MEMBER_BASIC':
              isMember = true;
              membershipType = 'Basic';
              roles.push('Basic Member');
              break;
              case 'ROLE_MEMBER_STARTER':
              isMember = true;
              membershipType = 'Starter';
              roles.push('Starter Member');
              break;
            case 'ROLE_MEMBER_PRO':
              isMember = true;
              membershipType = 'Professional';
              roles.push('Professional Member');
              break;
            case 'ROLE_ADMIN':
              isInternal = true;
              roles.push('Admin');
              break;
          }
        }
      }
    }

    if(isInternal) {
      parsedUserProfile['roles'] = roles;
      parsedUserProfile['membershipType'] = null;
    }
    else {
      parsedUserProfile['roles'] = null;
      if(isMember) {
        parsedUserProfile['membershipType'] = membershipType;
      }
      else {
        parsedUserProfile['membershipType'] = null;
      }
    }

    return parsedUserProfile;
  }

  onEditUserProfile(event) {
    this.isEditMode = true;

    this.userProfileForm.get('firstName').setValidators(this.formFieldValidators.firstName);
    this.userProfileForm.get('lastName').setValidators(this.formFieldValidators.lastName);
    this.userProfileForm.get('companyName').setValidators(this.formFieldValidators.companyName);
    this.resetUserProfileForm();

    this.firstNameInput.focused = true;
    this.firstNameInput.focus();
  }

  onCancelEditUserProfile(event) {
    this.userProfileForm.get('firstName').setValidators(null);
    this.userProfileForm.get('lastName').setValidators(null);
    this.userProfileForm.get('companyName').setValidators(null);

    this.resetUserProfileForm();
    this.isEditMode = false;
  }

  onUpdateUserProfile(event) {
    this._errors = [];
    this.isLoading = true;
    this._securityRepo.updateUserProfile(this.userProfileForm.value)
    .pipe(catchError((err) => {
      this.isLoading = false;
      let result = {
        errors: {
          detail: 'Profile update request failed!'
        }
      };

      return observableOf(result);
    }))
    .subscribe(result => {
      this.isLoading = false;
      let hasResult = result && typeof result === 'object';
      if(hasResult && result['isSuccess'] === true) {
        if(result['userProfile'] && typeof result['userProfile'] === 'object') {
          this._userProfile = this.parseUserProfile(result['userProfile']);
          this.resetUserProfileForm();
        }
        else {
          this.errors.push('Failed to load user profile! Try to refresh the page.');
        }

        this.isEditMode = false;
        this._snackBar.open('The changes to your profile were saved!', 'ok', { duration: 7000 })

        return;
      }

      let hasErrors = hasResult && result['errors'] && typeof result['errors'] === 'object';
      let errors = hasErrors ? result['errors'] : {};
      this.displayErrors(errors);
    });
  }

  onChangePassword(event) {
    let config = {
      data: {}
    };

    const dialogRef = this._matDialog.open(ChangePasswordDialogComponent, config);
  }

  private displayErrors(errors) {
    let hasValidationErrors = typeof errors['validationErrors'] === 'object' && errors['validationErrors'];
    let hasInvalidFields = false;
    if(hasValidationErrors && Array.isArray(errors['validationErrors']['violations'])) {
      for(let violation of errors['validationErrors']['violations']) {
        let title = 'Unknown error';
        if(violation['title'] && typeof violation['title'] === 'string') {
          title = violation['title'];
        }

        let filedControl = this.getControlByName(violation['propertyPath']);
        if(filedControl) {
          filedControl.setErrors({apiError: title});
          filedControl.markAsTouched();
          hasInvalidFields = true;
        }
      }
    }

    let hasPasswordValidationError = typeof errors['passwordValidationError'] === 'object' && errors['passwordValidationError'];
    if(hasPasswordValidationError) {
      let title = 'Unknown error';
      if(errors['passwordValidationError']['title'] && typeof errors['passwordValidationError']['title'] === 'string') {
        title = errors['passwordValidationError']['title'];
      }

      let filedControl = this.getControlByName(errors['passwordValidationError']['propertyPath']);
      if(filedControl) {
        filedControl.setErrors({apiError: title});
        filedControl.markAsTouched();
        hasInvalidFields = true;
      }

      hasInvalidFields = true;
    }

    let errorMessage = 'An unknown error ocured! Please try again.';
    if(typeof errors['detail'] === 'string') {
      errorMessage = errors['detail'];
    }
    else if(hasInvalidFields) {
      errorMessage = 'Some fields have invalid values!';
    }

    this._errors.push(errorMessage);
  }

  private getControlByName(name) {
    switch(name) {
      case 'first_name':
      case 'firstName':
          return this.userProfileForm.get('firstName');
      case 'last_name':
      case 'lastName':
          return this.userProfileForm.get('lastName');
      case 'company_name':
      case 'companyName':
          return this.userProfileForm.get('companyName');
      case 'email':
          return this.userProfileForm.get('email');
      case 'password':
          return this.userProfileForm.get('password');
    }

    return null;
  }
}
