import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';
import { PartRequestsRepoService } from 'src/app/repositories/part-requests-repo/part-requests-repo.service';
import { DipTabulatorComponent } from 'src/app/shared/dip-tabulator/dip-tabulator.component';
import { PartRequestsFormDialogComponent } from './part-requests-form-dialog/part-requests-form-dialog.component';
import { PartRequestsConfirmationDialogComponent } from './part-requests-confirmation-dialog/part-requests-confirmation-dialog.component';
import { SharedService } from 'src/app/shared/service/shared.service';
import { PartRequestInfoDialogComponent } from './info-dialog/part-requests-info-dialog.component';

@Component({
  selector: 'app-part-requests',
  templateUrl: './part-requests.component.html',
  styleUrls: ['./part-requests.component.scss']
})
export class PartRequestsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  isLoading: boolean = false;
  isNewPartRequestLoading: boolean = false;

  private _errors = [];
  get errors() { return this._errors };

  private _isTableCreated: boolean = false;
  private _tableWrapperHeight = 0;

  @ViewChild('partRequestsTableWrapper', {read: ElementRef}) partRequestsTableWrapper: ElementRef;
  @ViewChild('partRequestsTable', {read: DipTabulatorComponent}) partRequestsTable: DipTabulatorComponent;

  partRequestForm = this._formBuilder.group({
    partNumber: ['', [Validators.required]],
    manufacturer: [''],
    function: [''],
    dataType: [''],
    notes: ['']
  });

  eventData: any;
  isAuthenticated: any;

  constructor(
    private _securityRepoService: SecurityRepoService,
    private _partRequestsRepo: PartRequestsRepoService,
    private _formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    private sharedService: SharedService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.sharedService.eventObservable$.subscribe((data) => {

      if(data instanceof Array){

        this.eventData = data;
        this.partRequestsTable.setFilter(data)

      } else if (typeof data == 'string') {

        this.partRequestsTable.setFilter(this.eventData)

      }
    });
    this.isAuthenticated = this._securityRepoService.isAuthenticated;
  }

  ngAfterViewInit() {
    this._tableWrapperHeight = this.partRequestsTableWrapper.nativeElement.offsetHeight;
    this.partRequestsTable.createTable(this.getTableConfig());
    this._isTableCreated = true;
    this.partRequestsTable.createShowMorePartRequest();

  }

  ngAfterViewChecked() {
    let height = this.partRequestsTableWrapper.nativeElement.offsetHeight;
    if(this._tableWrapperHeight !== height) {
      this._tableWrapperHeight = height;
      if(this._isTableCreated) {
        this.partRequestsTable.table.redraw(true);
      }
    }
  }

  private getTableConfig() {
    let partRequestsRepo = this._partRequestsRepo;
    let ajaxRequestFunc = (url, config, params) => {
      return new Promise(function(resolve, reject) {
        partRequestsRepo.getPartRequests(params)
        .pipe(catchError(() => {
          reject();
          return observableOf([]);
        }))
        .subscribe(data => {
          resolve(data);

          let configs = null;
          if(data && typeof data === 'object' && data['configs']) {
            configs = data['configs'];
          }
        });
      });
    }

    let tableConfig = {
      height: '100%',
      layout: 'fitColumns', //fit columns to width of table (optional)
      responsiveLayout: true,
      resizableRows: true,
      columns: this.getColumnDefs(),
      placeholder: 'No data to display!',
      ajaxURL: partRequestsRepo.getAjaxUrl(),
      ajaxConfig: 'GET',
      ajaxRequestFunc: ajaxRequestFunc,
      ajaxFiltering: true,
      ajaxSorting: true,
      pagination: 'remote',
      paginationSize: 30,
      paginationSizeSelector: true,
      paginationDataReceived: { 'data': 'items' },
      dataTree:true,
      // dataTreeStartExpanded:false,
      dataTreeChildField:"children",
      // rowClick: openFormDialog
      rowMouseEnter:function(e, row){ row.getCell("action").getElement().querySelector(".part-request_action-buttons").style.display = "flex"},
      rowMouseLeave:function(e, row){ row.getCell("action").getElement().querySelector(".part-request_action-buttons").style.display = "none"}
    };

    return tableConfig;
  }

  private getColumnDefs() {
    let hasUserField = this._securityRepoService.isAdmin ? true : false;
    let userId = this._securityRepoService.user ? this._securityRepoService.user.id : 0;
    let columnDefs = [
      { title: "Part Number",  field: "part_number",                         headerSort: true, headerSortTristate: true },
      { title: "Manufacturer", field: "manufacturer",                        headerSort: true, headerSortTristate: true },
      { title: "Function",     field: "function",                            headerSort: true, headerSortTristate: true },
      { title: "Data Type",    field: "data_type",                           headerSort: true, headerSortTristate: true,
        formatter: function(cell, formatterParams, onRendered) {
          const row = cell.getRow();
          const rowData = row.getData();
          const parent = row.getTreeParent();

          if (parent !== false && typeof parent.getData().data_type == 'string') {
            let parentDataArray = parent.getData().data_type.split(', ');
            let formattedData = [];

            for(let data of rowData.data_type.split(', ')) {
              if (parentDataArray.includes(data)) {
                formattedData.push('<span style="color:darkorange; font-weight: 600; text-decoration: underline;">' + data + '</span>');
              } else {
                formattedData.push(data);
              }
            }

            return formattedData.join(', ');
          } else {
            return rowData.data_type;
          }
        }
       },
      { title: "Notes",        field: "notes",        visible: hasUserField, headerSort: true, headerSortTristate: true },
      { title: "ID",           field: "id",           visible: hasUserField, headerSort: true, headerSortTristate: true },
      { title: "User ID",      field: "user.id",      visible: hasUserField, headerSort: true, headerSortTristate: true },
      { title: "User",         field: "user",         visible: hasUserField, headerSort: true, headerSortTristate: true,
        formatter: function(cell) {
          let user = cell.getValue();
          let formattedValue = '';
          if(user && typeof user === 'object') {
            formattedValue = user.first_name + ' ' + user.last_name + '(' + user.username + ')';
          }

          return formattedValue;
        }
      },
      { title: "User Email",   field: "user.email",   visible: hasUserField, headerSort: true, headerSortTristate: true },
      { title: "Status",       field: "status",       visible: hasUserField, headerSort: true, headerSortTristate: true,
        formatter: function(cell) {
          let status = cell.getValue();
          return status == '1' ? 'Open' : 'Closed';
        }
      },
      { title: "Action",       field: "action",
        formatter: function(cell, formatterParams, onRendered) {
          const row = cell.getRow();
          const rowData = row.getData();

          let buttonsElement = '<div style="justify-content: space-evenly; display: none;" class="part-request_action-buttons">';

          let buttons = '';

          if (rowData.children !== null && rowData.status == 1) {
            row.getElement().style.backgroundColor = '#ffdbbb';
          }

          if (userId == rowData.user.id) {
              buttons = buttons + '<span class="material-icons check-icon" title="Added">check</span>';
          } else {
            buttons = buttons + '<span class="material-icons add-icon" title="Also Interested">add</span>';
            buttons = buttons + '<span class="material-icons alt_route-icon" title="More options">alt_route</span>';
          }

          if ((userId == rowData.user.id) || hasUserField) {

            if (rowData.status == 1) {
              buttons = buttons + `<span class="material-icons lock_open-icon" title="Close my request">lock_open</span>`;
            } else {
              row.getElement().style.backgroundColor = '#f6f6f6';
              buttons = buttons + `<span class="material-icons lock-icon" title="Open Request">lock</span>`;
            }
            buttons = buttons + `<span class="material-icons edit-icon" title="Edit Request">edit</span>`;

            if (hasUserField) {
              if (rowData.is_show == true) {
                buttons = buttons + `<span class="material-icons visibility-icon" title="Hide request">visibility</span>`;
              } else {
                buttons = buttons + `<span class="material-icons visibility_off-icon" title="Show request">visibility_off</span>`;
              }
            }
          }

          return buttonsElement + buttons + '</div>';

        },
        cellClick:  this.handleActionClick.bind(this)
      },
    ];

    return columnDefs;
  }

  handleActionClick(e, cell) {
    var row = cell.getRow();

    if (e.target.classList.contains('add-icon')) {
      this.onAlsoInterested(row);
    }

    if (e.target.classList.contains('alt_route-icon')) {
      this.openFormDialog(e, row);
    }

    if (e.target.classList.contains('edit-icon')) {
      this.openFormDialog(e, row);
    }

    if (e.target.classList.contains('lock_open-icon') || e.target.classList.contains('lock-icon')) {
      row = row.getData()
      this._partRequestsRepo.changeStatusPartRequest({'id': row.id}).subscribe(data => {
        this.partRequestsTable.setFilter(this.eventData)
      });
    }

    if (e.target.classList.contains('visibility_off-icon') || e.target.classList.contains('visibility-icon')) {
      row = row.getData()
      this._partRequestsRepo.changeShowPartRequest({'id': row.id}).subscribe(data => {
        this.partRequestsTable.setFilter(this.eventData)
      });
    }
  }

  openFormDialog(e, row) {

    let config = {
      data: {
        defaultValues: {
          partNumber: '',
          manufacturer: '',
          function: '',
          dataType: [],
          user_id: 0,
          parentRequestId: 0,
          partRequestId: 0
        }
      }
    };

    const rowData = row.getData();

    config.data.defaultValues.partNumber = rowData.part_number;
    config.data.defaultValues.manufacturer = rowData.manufacturer;
    config.data.defaultValues.function = rowData.function;
    config.data.defaultValues.dataType = rowData.data_type;
    config.data.defaultValues.user_id = rowData.user.id;
    config.data.defaultValues.partRequestId = rowData.id;

    if (row.getTreeParent()) {
      config.data.defaultValues.parentRequestId = row.getTreeParent().getData().id;
    } else {
      config.data.defaultValues.parentRequestId = 0;
    }

    const dialogRef = this._matDialog.open(PartRequestsFormDialogComponent, config);
  };

  onMakeNewPartRequest(event) {
    if (this.isAuthenticated) {
      const dialogRef = this._matDialog.open(PartRequestsFormDialogComponent);
    }
  }

  public onMoreInfo() {
    let btn = document.getElementById("info-icon");
    let dialogTop = btn.offsetTop;
    let dialogleft = btn.offsetLeft;
    this._matDialog.open(PartRequestInfoDialogComponent,
        {
        height: "375px",
        width: "500px",
        backdropClass: 'g-transparent-backdrop',
        panelClass: 'info-dialog',
        disableClose: false,
        hasBackdrop: true,
        autoFocus: false,
        position: {
            left: `${dialogleft - 250}px`,
            top: `${dialogTop + 140}px`
        }
    });
  }

  onAlsoInterested(row) {
    let config = {
      data: {
        defaultValues: {
          partNumber: '',
          manufacturer: '',
          function: '',
          dataType: [],
          user_id: 0,
          partRequestId: 0
        }
      }
    };

    const rowData = row.getData();

    config.data.defaultValues.partNumber = rowData.part_number;
    config.data.defaultValues.manufacturer = rowData.manufacturer;
    config.data.defaultValues.function = rowData.function;
    config.data.defaultValues.dataType = rowData.data_type;
    config.data.defaultValues.user_id = rowData.user.id;

    if (row.getTreeParent()) {
      config.data.defaultValues.partRequestId = row.getTreeParent().getData().id;
    } else {
      config.data.defaultValues.partRequestId = rowData.id;
    }

    const dialogRef = this._matDialog.open(PartRequestsConfirmationDialogComponent, config);
  }

  onClickShowMore() {

  }

}
