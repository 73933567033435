<div class="products-filters-wrapper">
  <div class="products-filters-toolbar">

    <div class="filters-toolbar-button-container" (click)="applyFilter($event)">
      <button mat-mini-fab class="toolbar-item">
        <mat-icon>search</mat-icon>
      </button>
      <div class="filters-toolbar-icon-text">Search</div>
    </div>
    <div class="filters-toolbar-button-container" (click)="clearFilters($event)">
      <button mat-mini-fab class="toolbar-item">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="filters-toolbar-icon-text">Clear</div>
    </div>
  </div>
  <div class="products-filters-inputs" (scroll)="onScroll($event)">

    <mat-form-field floatLabel="auto" appearance="standard" [ngClass]="{'active': isPartNumberFilterActive}">
      <mat-icon matPrefix class="left-icon">label</mat-icon>
      <input matInput type="text" placeholder="Part Number" (keyup.enter)="applyFilter($event)"
        (click)="clearAutoComplete()" [matAutocomplete]="auto" (input)="getAutoComplete($event, 'part_number')"
        #partNumberFilterElement />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="clearAutoComplete()">
        <mat-option *ngFor="let option of autoCompleteArray" [value]="option">{{option}}</mat-option>
      </mat-autocomplete>
      <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
    </mat-form-field>

    <mat-form-field floatLabel="auto" appearance="standard" [ngClass]="{'active': isManufacturerFilterActive}">
      <mat-icon matPrefix class="left-icon">label</mat-icon>
      <input matInput type="text" placeholder="Manufacturer" (keyup.enter)="applyFilter($event)" #manufacturerFilterElement />
      <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
    </mat-form-field>

    <mat-form-field floatLabel="auto" appearance="standard" [ngClass]="{'active': isFunctionFilterActive}">
      <mat-icon matPrefix class="left-icon">label</mat-icon>
      <input matInput type="text" placeholder="Function" (keyup.enter)="applyFilter($event)" #functionFilterElement />
      <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
    </mat-form-field>

    <div>
      <mat-form-field class="flex-row" floatLabel="auto" appearance="standard" #dataType>
        <mat-icon matPrefix class="left-icon">label</mat-icon>
        <div class="multi-select-container">
          <mat-select placeholder="Data Type" multiple (selectionChange)="applyFilter($event, 'selectDataType')"
            #dataTypeFilterElement>
            <mat-select-trigger class="multi-select-trigger">Data Type</mat-select-trigger>
            <mat-option *ngFor="let client of dataTypeSelectData" [value]="client.id">
              {{client.clientName}}
            </mat-option>
          </mat-select>
        </div>
      </mat-form-field>
    </div>

    <mat-form-field *ngIf="isAdmin" floatLabel="auto" appearance="standard" [ngClass]="{'active': isIdFilterActive}">
      <mat-icon matPrefix class="left-icon">label</mat-icon>
      <input matInput type="text" placeholder="ID" (keyup.enter)="applyFilter($event)" #idFilterElement />
      <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
    </mat-form-field>

    <mat-form-field *ngIf="isAdmin" floatLabel="auto" appearance="standard" [ngClass]="{'active': isUsernameFilterActive}">
      <mat-icon matPrefix class="left-icon">label</mat-icon>
      <input matInput type="text" placeholder="Username" (keyup.enter)="applyFilter($event)" #usernameFilterElement />
      <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
    </mat-form-field>

    <div>
      <mat-form-field class="flex-row" floatLabel="auto" appearance="standard" #myRequests
        [ngClass]="{'active': isMyRequestsFilterActive}">
        <mat-icon matPrefix class="left-icon">label</mat-icon>
        <div class="multi-select-container">
          <mat-select placeholder="My Requests" (selectionChange)="applyFilter($event, 'selectMyRequests')" #myRequestsFilterElement>
            <mat-select-trigger class="multi-select-trigger">My Requests</mat-select-trigger>
            <mat-option *ngFor="let client of myRequestsSelectData" [value]="client.id">
              {{client.clientName}}
            </mat-option>
          </mat-select>
        </div>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="flex-row" floatLabel="auto" appearance="standard"
        #statusTooltip="matTooltip"
        [ngClass]="{'active': isStatusFilterActive}"
        [matTooltip]="viewConfigs.filterInputs.status.tooltipMessage"
        matTooltipShowDelay="5000"
        [matTooltipDisabled]="(isAdmin && isMyRequestsFilterActive)"
        (click)="statusTooltip.show(0)" #status
        >
        <mat-icon matPrefix class="left-icon">label</mat-icon>
        <div class="multi-select-container">
          <!-- <mat-label>Status</mat-label> -->
          <mat-select placeholder="Status" (selectionChange)="applyFilter($event, 'selectStatus')"
            [disabled]="(!isAdmin && !isMyRequestsFilterActive)"
            #statusFilterElement>
            <mat-select-trigger class="multi-select-trigger">Status</mat-select-trigger>
            <mat-option *ngFor="let client of statusSelectData" [value]="client.id">
              {{client.clientName}}
            </mat-option>
          </mat-select>
          <div class="disabled-input-click"
          *ngIf="(!isAdmin && !isMyRequestsFilterActive)"></div>
        </div>
      </mat-form-field>
    </div>

    <div *ngIf="isAdmin">
      <mat-form-field class="flex-row" floatLabel="auto" appearance="standard"
        #isShowTooltip="matTooltip"
        [ngClass]="{'active': isIsShowFilterActive}"
        [matTooltip]="viewConfigs.filterInputs.isShow.tooltipMessage"
        matTooltipShowDelay="5000"
        [matTooltipDisabled]="(isAdmin && isMyRequestsFilterActive)"
        (click)="isShowTooltip.show(0)" #isShow
        >
        <mat-icon matPrefix class="left-icon">label</mat-icon>
        <div class="multi-select-container">
          <mat-select placeholder="Display" (selectionChange)="applyFilter($event, 'selectIsShow')"
            [disabled]="(!isAdmin && !isMyRequestsFilterActive)"
            #isShowFilterElement>
            <mat-select-trigger class="multi-select-trigger">Display</mat-select-trigger>
            <mat-option *ngFor="let client of isShowSelectData" [value]="client.id">
              {{client.clientName}}
            </mat-option>
          </mat-select>
          <div class="disabled-input-click"
          *ngIf="(!isAdmin && !isMyRequestsFilterActive)"></div>
        </div>
      </mat-form-field>
    </div>

  </div>
</div>
