import { Component, OnInit, TemplateRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

import { PartRequestsRepoService } from 'src/app/repositories/part-requests-repo/part-requests-repo.service';
import { DipTabulatorComponent } from 'src/app/shared/dip-tabulator/dip-tabulator.component';
import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-part-requests-filter',
  templateUrl: './part-requests-filter.component.html',
  styleUrls: ['./part-requests-filter.component.scss']
})
export class PartRequestsFilterComponent implements OnInit {
  tooltipShowDelay = 400;

  @Output() callParentFunction = new EventEmitter<any>();

  @ViewChild('tabulatorTable', { read: DipTabulatorComponent }) tabulatorTable: DipTabulatorComponent;
  @ViewChild('confirmationDialog', { static: true }) confirmationDialog: TemplateRef<any>;
  @ViewChild('errorDialog', { static: true }) errorDialog: TemplateRef<any>;
  @ViewChild('usersSidenavContainer', { read: MatSidenavContainer }) usersSidenavContainer: MatSidenavContainer;

  @ViewChild('partNumberFilterElement', { read: ElementRef }) partNumberFilterElement: ElementRef;
  @ViewChild('manufacturerFilterElement', { read: ElementRef }) manufacturerFilterElement: ElementRef;
  @ViewChild('idFilterElement', { read: ElementRef }) idFilterElement: ElementRef;
  @ViewChild('usernameFilterElement', { read: ElementRef }) usernameFilterElement: ElementRef;
  @ViewChild('dataTypeFilterElement', { read: MatSelect }) dataTypeFilterElement: MatSelect;
  @ViewChild('functionFilterElement', { read: ElementRef }) functionFilterElement: ElementRef;
  @ViewChild('myRequestsFilterElement', {read: MatSelect}) myRequestsFilterElement: MatSelect;
  @ViewChild('statusFilterElement', {read: MatSelect}) statusFilterElement: MatSelect;
  @ViewChild('isShowFilterElement', {read: MatSelect}) isShowFilterElement: MatSelect;

  @ViewChild('usersTableContainer', { read: ElementRef }) usersTableContainer: ElementRef;

  private _isUsersTableCreated: boolean = false;
  private _usersTableWrapperHeight = 0;

  isFiltersInputContainerScrolled: boolean = false;

  rowPosition: number = 0;

  autoCompleteArray = [];

  viewConfigs = {
    filterInputs: {
        status: {
            isDisabled: true,
            tooltipMessage: 'Please, enable your requests first'
        },
        isShow: {
            isDisabled: true,
            tooltipMessage: 'Please, enable your requests first'
        },
    }
  };

  autopopMsg2 = 'Not accessible from this tab';

  dataTypeSelectData = [
    {
      id: 'Heavy ion SEE',
      clientName: 'Heavy ion SEE',
      checked: false
    },
    {
      id: 'Gamma ray',
      clientName: 'Gamma ray TID',
      checked: false
    },
    {
      id: 'Proton',
      clientName: 'Proton',
      checked: false
    },
    {
      id: 'Neutron',
      clientName: 'Neutron',
      checked: false
    },
    {
      id: 'Other',
      clientName: 'Other',
      checked: false
    },
  ];

  myRequestsSelectData = [
    {
      id: 0,
      clientName: 'Show all',
      checked: false
    },
    {
      id: 1,
      clientName: 'Show my requests only',
      checked: false
    },
  ];

  isShowSelectData = [
    {
      id: 0,
      clientName: 'Hidden',
      checked: false
    },
    {
      id: 1,
      clientName: 'Shown',
      checked: false
    },
  ];

  statusSelectData = [
    {
      id: 0,
      clientName: 'Closed',
      checked: false
    },
    {
      id: 1,
      clientName: 'Open',
      checked: false
    }
  ];


  filters: any = {
    partNumber: '',
    manufacturer: '',
    id: '',
    username: '',
    dataType: '',
    status: 2,
    isShow: 2,
    function: '',
    myRequests: 0
  }

  isAdmin = false;

  isPartNumberFilterActive: boolean = false;
  isManufacturerFilterActive: boolean = false;
  isIdFilterActive: boolean = false;
  isUsernameFilterActive: boolean = false;
  isUserRoleFilterActive: boolean = false;
  isFunctionFilterActive: boolean = false;
  isMyRequestsFilterActive: boolean = false;
  isStatusFilterActive: boolean = false;
  isIsShowFilterActive: boolean = false;

  constructor(
    private _partRequestsRepo: PartRequestsRepoService,
    private _securityRepoService: SecurityRepoService,
    private matDialog: MatDialog,
    private router: Router,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    let defaultValues = {
      partNumber: '',
      manufacturer: '',
      function: '',
      dataType: '',
      notes: ''
    };

    this.isAdmin = this._securityRepoService.isAdmin;
  }

  applyFilter(event: any, type = '') {
    let filterChanged: boolean = false;
    let newValue = '';

    newValue = this.partNumberFilterElement.nativeElement.value.trim().toLowerCase();
    if (this.filters.partNumber !== newValue) {
      this.filters.partNumber = newValue;
      filterChanged = true;
    }

    newValue = this.manufacturerFilterElement.nativeElement.value.trim().toLowerCase();
    if (this.filters.manufacturer !== newValue) {
      this.filters.manufacturer = newValue;
      filterChanged = true;
    }

    newValue = this.functionFilterElement.nativeElement.value.trim().toLowerCase();
    if (this.filters.function !== newValue) {
      this.filters.function = newValue;
      filterChanged = true;
    }

    if (type === 'selectDataType') {
      newValue = event.value;
      if (this.filters.dataType !== newValue) {
        this.filters.dataType = newValue;
        filterChanged = true;
      }
    }

    if (type === 'selectStatus') {
      newValue = event.value;
      if (this.filters.status !== newValue) {
        this.filters.status = newValue;
        filterChanged = true;
      }
    }

    if (type === 'selectMyRequests') {
      newValue = event.value;
      if (this.filters.myRequests !== newValue) {
        this.filters.myRequests = newValue;
        filterChanged = true;
      }
    }


    if (this._securityRepoService.isAdmin) {
      newValue = this.idFilterElement.nativeElement.value.trim().toLowerCase();
      if (this.filters.id !== newValue) {
        this.filters.id = newValue;
        filterChanged = true;
      }

      newValue = this.usernameFilterElement.nativeElement.value.trim().toLowerCase();
      if (this.filters.username !== newValue) {
        this.filters.username = newValue;
        filterChanged = true;
      }

      if (type === 'selectIsShow') {
        newValue = event.value;
        if (this.filters.isShow !== newValue) {
          this.filters.isShow = newValue;
          filterChanged = true;
        }
      }
    }

    if (filterChanged) {
      this.setActiveStates();
      this.clearAutoComplete();

      let tabFilters = this.buildFiltersQuery();

      this.sharedService.emitEvent(tabFilters);
    }

  }

  private setActiveStates() {

    if (this.filters.partNumber == '') {
      this.isPartNumberFilterActive = false;
    } else {
      this.isPartNumberFilterActive = true;
    }

    if (this.filters.manufacturer == '') {
      this.isManufacturerFilterActive = false;
    } else {
      this.isManufacturerFilterActive = true;
    }

    if (this.filters.id == '') {
      this.isIdFilterActive = false;
    } else {
      this.isIdFilterActive = true;
    }

    if (this.filters.username == '') {
      this.isUsernameFilterActive = false;
    } else {
      this.isUsernameFilterActive = true;
    }

    if (this.filters.userRole == '') {
      this.isUserRoleFilterActive = false;
    } else {
      this.isUserRoleFilterActive = true;
    }

    if (this.filters.status == 2) {
      this.isStatusFilterActive = false;
    } else {
      this.isStatusFilterActive = true;
    }

    if (this.filters.isShow == 2) {
      this.isIsShowFilterActive = false;
    } else {
      this.isIsShowFilterActive = true;
    }

    if (this.filters.myRequests == 0) {
      this.isMyRequestsFilterActive = false;
    } else {
      this.isMyRequestsFilterActive = true;
    }

    if (this.filters.function == '') {
      this.isFunctionFilterActive = false;
    } else {
      this.isFunctionFilterActive = true;
    }

  }

  private buildFiltersQuery() {
    let filtersQuery = [];

    if (typeof this.filters.partNumber === 'string' && this.filters.partNumber.length > 0) {
      let filter = {
        'field': 'part_number',
        'type': 'like',
        'value': this.filters.partNumber
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.manufacturer === 'string' && this.filters.manufacturer.length > 0) {
      let filter = {
        'field': 'manufacturer',
        'type': 'like',
        'value': this.filters.manufacturer
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.id === 'string' && this.filters.id.length > 0) {
      let filter = {
        'field': 'id',
        'type': '=',
        'value': this.filters.id
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.username === 'string' && this.filters.username.length > 0) {
      let filter = {
        'field': 'username',
        'type': 'like',
        'value': this.filters.username
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.function === 'string' && this.filters.function.length > 0) {
      let filter = {
        'field': 'function',
        'type': 'like',
        'value': this.filters.function
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.dataType === 'object' && this.filters.dataType.length > 0) {
      let filter = {
          'field': 'data_type',
          'type': 'like',
          'value': this.filters.dataType
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.status === 'number' && this.filters.status < 2) {
      let filter = {
        'field': 'status',
        'type': '=',
        'value': this.filters.status
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.isShow === 'number' && this.filters.isShow < 2) {
      let filter = {
        'field': 'is_show',
        'type': '=',
        'value': this.filters.isShow
      };

      filtersQuery.push(filter);
    }

    if (typeof this.filters.myRequests === 'number' && this.filters.myRequests != 0) {
      let filter = {
        'field': 'user',
        'type': '=',
        'value': this._securityRepoService.user.id
      };

      filtersQuery.push(filter);
    }

    return filtersQuery;
  }

  clearFilters(event: Event) {
    let isFilterChanged: boolean = false;
    let newValue = '';

    if (this.filters.partNumber !== newValue) {
      this.filters.partNumber = newValue;
      isFilterChanged = true;
      this.clearAutoComplete();
    }
    this.partNumberFilterElement.nativeElement.value = '';

    if (this.filters.manufacturer !== newValue) {
      this.filters.manufacturer = newValue;
      isFilterChanged = true;
    }
    this.manufacturerFilterElement.nativeElement.value = '';

    if (this.filters.function !== newValue) {
      this.filters.function = newValue;
      isFilterChanged = true;
    }
    this.functionFilterElement.nativeElement.value = '';

    if (this.filters.dataType !== newValue) {
      this.filters.dataType = newValue;
      isFilterChanged = true;
    }
    this.dataTypeFilterElement.options.forEach((data: MatOption) => data.deselect());
    this.dataTypeFilterElement.value = null;

    if (this.filters.status !== 2) {
      this.filters.status = 2;
      isFilterChanged = true;
    }
    this.statusFilterElement.value = null;

    if (this.filters.myRequests != 0) {
      this.filters.myRequests = 0;
      isFilterChanged = true;
    }
    this.myRequestsFilterElement.value = null;

    if (this._securityRepoService.isAdmin) {
      if (this.filters.id !== newValue) {
        this.filters.id = newValue;
        isFilterChanged = true;
      }
      this.idFilterElement.nativeElement.value = '';

      if (this.filters.username !== newValue) {
        this.filters.username = newValue;
        isFilterChanged = true;
      }
      this.usernameFilterElement.nativeElement.value = '';

      if (this.filters.isShow !== 2) {
        this.filters.isShow = 2;
        isFilterChanged = true;
      }
      this.isShowFilterElement.value = null;
    }

    if (isFilterChanged) {
      this.setActiveStates();
      this.sharedService.emitEvent([]);
    }
  }

  getAutoComplete(event, type) {
    let queryString = event.target.value;

    if (queryString.length >= 3) {
      this._partRequestsRepo.getAutocomplete({ 'column': type, 'data': queryString }).subscribe(data => {
        this.autoCompleteArray = data;
      });
    }
  }

  clearAutoComplete() {
      this.autoCompleteArray = [];
  }

  onScroll(event) {
    if (event.target.scrollTop > 2) {
      this.isFiltersInputContainerScrolled = true;
    } else {
      this.isFiltersInputContainerScrolled = false;
    }
  }
}
