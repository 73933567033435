<div class="loading-shade" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<mat-sidenav-container #userDetailSidenavContainer class="user-detail-sidenav-container">
  <mat-sidenav mode="side" opened class="user-detail-sidenav"
               [fixedInViewport]="false" [fixedTopGap]="0" [fixedBottomGap]="0" [disableClose]="true">
    <!-- Left bar -->
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="user-detail-content-wrapper">
      <div class="user-detail-content">
        <div *ngIf="!user && !isLoading">Invalid user</div>
        <div class="user-detail-col-wrapper" *ngIf="user">
          <div class="user-detail-col-one">
            <div [formGroup]="userAccountForm">
              <div>
                <mat-form-field floatLabel="auto" appearance="standard">
                  <mat-label>Username</mat-label>
                  <input matInput type="text" formControlName="username" required />
                  <mat-error *ngIf="userAccountForm.get('username').invalid">{{ getUsernameError() }}</mat-error>
                </mat-form-field>
                <button mat-raised-button color="primary"
                        *ngIf="this.userAccountForm.get('username').value !== user?.username"
                        (click)="onUpdateUserAccount()"
                        class="username-update-button">
                  Update
                </button>
              </div>
              <div>
                <mat-form-field floatLabel="auto" appearance="standard">
                  <mat-label>First Name</mat-label>
                  <input matInput type="text" [value]="user?.first_name" readonly />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field floatLabel="auto" appearance="standard">
                  <mat-label>Last Name</mat-label>
                  <input matInput type="text" [value]="user?.last_name" readonly />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field floatLabel="auto" appearance="standard">
                  <mat-label>Company</mat-label>
                  <input matInput type="text" [value]="user?.company_name" readonly />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field floatLabel="auto" appearance="standard">
                  <mat-label>E-mail</mat-label>
                  <input matInput type="text" [value]="user?.email" readonly />
                </mat-form-field>
              </div>

              <div *ngIf="hasOrganizationsLoadError" class="error-message">Failed to load the list of organizations!</div>
              <div>
                <mat-form-field floatLabel="auto" appearance="standard">
                  <mat-label>Organization</mat-label>
                  <mat-select formControlName="organization">
                    <mat-option value="">--None--</mat-option>
                    <ng-container *ngFor="let org of organizations">
                      <mat-option [value]="org.id">{{ org.name }}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
                <button mat-raised-button color="primary"
                        *ngIf="isOrganizationValueChanged()"
                        (click)="onUpdateUserAccount()"
                        class="username-update-button">
                  Update
                </button>
              </div>
            </div>
          </div>
          <div class="user-detail-col-two">
            <mat-card class="user-detail-section-card">
              <mat-card-header>
                <mat-card-title>Status</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="user-detail-status-grid-wrapper">
                  <div class="user-detail-status-grid-row">
                    <div class="user-detail-status-col-one">
                      <span class="mat-body-2">Registration Date:</span> {{ parseDate(user.createdAt) | date }}
                    </div>
                    <div class="user-detail-status-col-two"></div>
                  </div>

                  <div class="user-detail-status-grid-row">
                    <div class="user-detail-status-col-one">
                      <span class="mat-body-2">Account Status:</span> {{ user.account_status }}
                    </div>
                    <div class="user-detail-status-col-two">
                      <button mat-raised-button color="primary" *ngIf="isActive" (click)="onDisableUserAccount(user.id)">Disable</button>
                      <button mat-raised-button color="accent" *ngIf="!isActive" (click)="onActivateUserAccount(user.id)">Activate</button>
                    </div>
                  </div>

                  <ng-container *ngIf="isMember">
                    <div class="user-detail-status-grid-row">
                      <div class="user-detail-status-col-one">
                        <div class="user-detail-status-select-one">
                          <span class="mat-body-2">Membership Type:</span> {{ getMembershipType() }}
                        </div>
                      </div>
                      <div class="user-detail-status-col-two">
                        <div class="user-detail-status-select-one">
                          <mat-form-field appearance="outline">
                            <mat-label>Select Membership</mat-label>
                            <mat-select [(value)]="selectedMembership" (selectionChange)="onChangeMembership($event.value)">
                              <mat-option *ngFor="let membership of filteredMembershipOptions" [value]="membership.value">
                                {{ membership.label }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngIf="isProMember">
                      <div class="user-detail-status-col-one">
                        <span class="mat-body-2">Membership Duration:</span>
                      </div>
                      <mat-form-field appearance="standard">
                        <mat-label>Date range</mat-label>
                        <mat-date-range-input
                          [formGroup]="membershipDurationDatesForm"
                          [rangePicker]="picker"
                        >
                          <input matStartDate placeholder="Start date" formControlName="start" (dateChange)="updateMembershipDate($event)">
                          <input matEndDate placeholder="End date" formControlName="end" (dateChange)="updateMembershipDate($event)">
                        </mat-date-range-input>
                        <mat-error *ngIf="membershipDurationDatesForm.get('start').invalid">{{ getMembershipStartDateError() }}</mat-error>
                        <mat-error *ngIf="membershipDurationDatesForm.get('end').invalid">{{ getMembershipEndDateError() }}</mat-error>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker
                          #picker>
                        </mat-date-range-picker>
                      </mat-form-field>
                      <div class="btn-date-range">
                        <button mat-raised-button color="primary"
                        (click)="onApplyMembershipDateRangeFilter()"
                        [disabled] = "!membershipDurationDatesForm.valid || isMembershipDurationLoading || initialMembershipStartDate"
                        class="username-update-button">
                          Apply
                        </button>
                      </div>
                      <div class="btn-date-range">
                        <button mat-raised-button color="primary"
                        (click)="onApplyMembershipDateRangeFilter(true)"
                        [disabled] = "!membershipDurationDatesForm.valid || isMembershipDurationLoading || initialMembershipStartDate"
                        class="username-update-button">
                          Apply for organization
                        </button>
                      </div>
                    </ng-container>
                    <div class="user-detail-status-grid-row" *ngIf="isBasicMember">
                      <div class="user-detail-status-col-one">
                        <span class="mat-body-2">Trial Status:</span>
                        <ng-container *ngIf="isInTrialPeriod"> Active until {{ trialEndDate | date }}</ng-container>
                        <ng-container *ngIf="!isInTrialPeriod"> Expired</ng-container>
                      </div>
                      <div class="user-detail-status-col-two">
                        <button mat-raised-button (click)="onResetTrialStartDate($event)">Reset Trial</button>
                        <button mat-raised-button *ngIf="isInTrialPeriod" (click)="onEndTrial($event)">End Trial</button>
                      </div>
                    </div>

                    <div class="user-detail-status-grid-row" *ngIf="!hasPassword">
                      <div class="user-detail-status-col-one">
                        <span class="mat-body-2">Token:</span>
                        <ng-container *ngIf="isInTokenPeriod"> Active until {{ tokenEndDate | date }}</ng-container>
                        <ng-container *ngIf="!isInTokenPeriod"> Expired</ng-container>
                      </div>
                      <div class="user-detail-status-col-two">
                        <button mat-raised-button [disabled]="isInTokenPeriod" (click)="onRegenToken()">Regenerate token</button>
                      </div>
                    </div>

                    <div class="user-detail-status-grid-row">
                      <div class="user-detail-status-col-one">
                        <span class="mat-body-2" *ngIf="user.ip !== null">IP:</span> {{ user?.ip }}
                      </div>
                      <div class="user-detail-status-col-two"></div>
                    </div>

                    <mat-form-field appearance="standard">
                      <mat-label>Date range</mat-label>
                      <mat-date-range-input
                        [formGroup]="loginDurationDatesForm"
                        [rangePicker]="picker"
                      >
                        <input matStartDate placeholder="Start date" formControlName="start">
                        <input matEndDate placeholder="End date" formControlName="end">
                      </mat-date-range-input>
                      <mat-error *ngIf="loginDurationDatesForm.get('start').invalid">{{ getLDStartDateError() }}</mat-error>
                      <mat-error *ngIf="loginDurationDatesForm.get('end').invalid">{{ getLDEndDateError() }}</mat-error>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                    <div class="btn-date-range">
                      <button mat-raised-button color="primary"
                      *ngIf="isLDDateRangeChanged()"
                      [disabled] = "!statisticsDatesForm.valid || isLDLoading"
                      (click)="onApplyLDDateRangeFilter()"
                      class="username-update-button">
                        Apply
                      </button>
                    </div>
                    <div class="user-detail-status-grid-row">
                      <div class="user-detail-status-col-one">
                        <span class="mat-body-2" *ngIf="loginDuration !== null">Login duration:</span> {{ loginDuration }}
                      </div>
                    </div>

                    <div class="user-detail-status-grid-row" *ngIf="isInFreeSearchOrTrialPeriod">
                      <div class="user-detail-status-col-one">
                        <div *ngIf="isInTrialPeriod" >
                          <span class="mat-body-2">Trial Search Count:</span> {{ user?.membership?.freeSearchCount }}<br />
                          <span class="mat-body-2">Trial Product Detail Count:</span> {{ user?.membership?.freeProductDetailCount }}
                        </div>
                        <div *ngIf="isInFreeSearchPeriod">
                          <span  class="mat-body-2">Free Search Count:</span> {{ user?.membership?.freeSearchCount }}<br />
                          <span  class="mat-body-2">Free Product Detail Count:</span> {{ user?.membership?.freeProductDetailCount }}
                        </div>
                      </div>
                      <div class="user-detail-status-col-two">
                        <button mat-raised-button (click)="onResetFreeSearchCounters($event)">Reset Counters</button>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <br />
                <span class="mat-body-2">User Roles</span>
                <ul *ngIf="user.roles">
                  <li *ngFor="let role of user.roles">
                    {{ getRoleTitle(role) }}
                  </li>
                </ul>
              </mat-card-content>
            </mat-card>
            <br />

            <!-- User's statistics ---------------------------------------------------------- -->
            <mat-card class="user-detail-section-card">
              <mat-card-header>
                <mat-card-title>Statistics</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-form-field appearance="standard">
                  <mat-label>Date range</mat-label>
                  <mat-date-range-input
                    [formGroup]="statisticsDatesForm"
                    [rangePicker]="statisticsDatesPicker"
                  >
                    <input matStartDate placeholder="Start date" formControlName="start">
                    <input matEndDate placeholder="End date" formControlName="end">
                  </mat-date-range-input>
                  <mat-error *ngIf="statisticsDatesForm.get('start').invalid">{{ getStatisticsStartDateError() }}</mat-error>
                  <mat-error *ngIf="statisticsDatesForm.get('end').invalid">{{ getStatisticsEndDateError() }}</mat-error>
                  <mat-datepicker-toggle matSuffix [for]="statisticsDatesPicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #statisticsDatesPicker></mat-date-range-picker>
                </mat-form-field>
                <ng-container *ngIf="isLoadingStatistics">
                  <mat-spinner></mat-spinner>
                </ng-container>
                <ng-container *ngIf="!isLoadingStatistics && statisticsDatesForm.valid">
                  <button mat-raised-button color="accent"
                          *ngIf="isDateRangeChanged()"
                          (click)="onApplyDateRangeFilter()"
                          class="username-update-button">
                    Apply
                  </button>
                  <button mat-raised-button color="primary"
                          *ngIf="!isDateRangeChanged()"
                          (click)="onReloadStatistics()"
                          class="username-update-button">
                    Reload
                  </button>
                </ng-container>

                <div class="error-message" *ngIf="hasStatisticsLoadError">Failed to load statistics!</div>
                <div *ngIf="canUpdateSearchLimit">
                  <mat-form-field floatLabel="auto" appearance="standard">
                    <mat-label>Search Limit</mat-label>
                    <input matInput type="text" [(ngModel)]="searchLimit" />
                  </mat-form-field>

                  <button mat-raised-button color="primary"
                          (click)="onChangeSearchLimit()"
                          class="username-update-button">
                    Update
                  </button>
                </div>
                <div class="user-detail-statistics-grid-wrapper" *ngIf="!hasStatisticsLoadError">
                  <div class="user-detail-statistics-grid-row">
                    <div class="user-detail-statistics-col-one">
                      <div class="mat-h4">User searches</div>
                      <ng-container *ngIf="statistics">
                        <ul>
                          <li>Part Number: {{ statistics.user.searches.partNumber }}</li>
                          <li>Part Number (BOM scrub): {{ statistics.user.searches.partNumberBomScrub }}</li>
                          <li *ngIf="isProMember">Exact Matches: {{ statistics.user.searches.exactMatches }}</li>
                          <li>Function:  {{ statistics.user.searches.function }}</li>
                          <li>Total:  {{ statistics.user.searches.total }}</li>
                        </ul>
                        <span class="mat-body-2">User modals: </span> {{ statistics.user.modals }}
                      </ng-container>
                    </div>
                    <div class="user-detail-statistics-col-two">
                      <div class="mat-h4">Organization searches</div>
                      <ng-container *ngIf="statistics">
                        <ul *ngIf="statistics">
                          <li>Part Number: {{ statistics.organization.searches.partNumber }}</li>
                          <li>Part Number (BOM scrub): {{ statistics.organization.searches.partNumberBomScrub }} </li>
                          <li *ngIf="isProMember">Exact Matches: {{ statistics.organization.searches.exactMatches }}</li>
                          <li>Function:  {{ statistics.organization.searches.function }}</li>
                          <li>Total:  {{ statistics.organization.searches.total }}</li>
                        </ul>
                        <span class="mat-body-2">Organization modal: </span> {{ statistics.organization.modals }}
                      </ng-container>
                    </div>
                  </div>
                  <hr style="width: 100%;" />
                  <div class="user-detail-statistics-grid-row">
                    <div class="user-detail-statistics-col-one">
                      <div class="mat-h4">User SMD searches</div>
                      <ng-container *ngIf="statistics">
                        <ul>
                          <li>Part Number: {{ statistics.user.searches_smd.partNumber }}</li>
                          <li>Vendor Part Number: {{ statistics.user.searches_smd.vendorPartNumber }}</li>
                          <li *ngIf="isProMember">Exact Matches: {{ statistics.user.searches_smd.exactMatches }}</li>
                          <li>Description:  {{ statistics.user.searches_smd.description }}</li>
                          <li>Total:  {{ statistics.user.searches_smd.total }}</li>
                        </ul>
                        <span class="mat-body-2">User SMD modals: </span> {{ statistics.user.modals_smd }}
                      </ng-container>
                    </div>
                    <div class="user-detail-statistics-col-two">
                      <div class="mat-h4">Organization SMD searches</div>
                      <ng-container *ngIf="statistics">
                        <ul *ngIf="statistics">
                          <li>Part Number: {{ statistics.organization.searches_smd.partNumber }}</li>
                          <li>Vendor Part Number: {{ statistics.organization.searches_smd.vendorPartNumber }} </li>
                          <li *ngIf="isProMember">Exact Matches: {{ statistics.organization.searches_smd.exactMatches }}</li>
                          <li>Description:  {{ statistics.organization.searches_smd.description }}</li>
                          <li>Total:  {{ statistics.organization.searches_smd.total }}</li>
                        </ul>
                        <span class="mat-body-2">Organization SMD modal: </span> {{ statistics.organization.modals_smd }}
                      </ng-container>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <!-- End user's statistics ------------------------------------------------------ -->
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #confirmationDialog let-data>
  <div mat-dialog-title>Action Confirmation</div>
  <div mat-dialog-content>
    <p>{{ data.msg }}</p>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-flat-button [mat-dialog-close]="false" color="warn">No</button>
    <button mat-button mat-flat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Yes</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #errorDialog let-data>
  <div mat-dialog-title>Error</div>
  <div mat-dialog-content>
    <p class="error-message">{{ data.msg }}</p>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-flat-button mat-dialog-close color="primary">Ok</button>
  </mat-dialog-actions>
</ng-template>
