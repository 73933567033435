<div *ngIf="isAuthenticated" class="part-request-form-dialog-content">
  <div class="loading-shade" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div mat-dialog-title>
    New Part Request
    <mat-icon mat-dialog-close class="part-request-form-dialog-close-icon">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div *ngIf="errors" class="error-message">
      <ng-container *ngFor="let error of errors">
        {{ error }}<br />
      </ng-container>
    </div>

    <div [formGroup]="partRequestForm">
      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-error *ngIf="partRequestForm.get('partNumber').invalid">{{ getPartNumberError() }}</mat-error>
            <input matInput type="text" placeholder="Part Number"
                formControlName="partNumber"
                (click)="clearAutoComplete()"
                [matAutocomplete]="auto" (input)="getAutoComplete($event, 'part_number')"
                #partNumberFilterElement cdkFocusInitial required />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="clearAutoComplete()">
                <mat-option *ngFor="let option of autoCompleteArray" [value]="option">{{option}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-label>Manufacturer</mat-label>
          <input matInput type="text" formControlName="manufacturer" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-label>Function</mat-label>
          <input matInput type="text" formControlName="function" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="flex-row" floatLabel="auto" appearance="standard" #dataType>
            <div class="multi-select-container">
                <mat-select placeholder="Data Type" multiple
                    #dataTypeElement formControlName="dataType">
                    <!-- <mat-select-trigger class="multi-select-trigger">Data Type</mat-select-trigger> -->
                    <mat-option *ngFor="let client of dataTypeSelectData" [value]="client.id">
                        {{client.clientName}}
                    </mat-option>
                </mat-select>
            </div>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName="notes"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button *ngIf="canEdit" mat-button [disabled]="partRequestForm.invalid" (click)="onSubmitPartRequest(true)">
      Edit
    </button>
    <button *ngIf="(!canEdit || isAdmin)" mat-button [disabled]="partRequestForm.invalid" (click)="onSubmitPartRequest()">
      Submit
    </button>
  </mat-dialog-actions>
</div>
