<div class="loading-shade" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<mat-sidenav-container #productsSidenavContainer class="products-sidenav-container">
    <mat-sidenav #sidenav mode="side" [opened]="!isTablet()" class="products-sidenav" [fixedInViewport]="false"
        [fixedTopGap]="0" [fixedBottomGap]="0" >

        <div class="products-filters-wrapper" [class.cdk-visually-hidden]="isTab(2)">
            <div class="products-filters-toolbar" [ngClass]="{'bottom-shadow': isFiltersInputContainerScrolled}">
                <div class="filters-toolbar-button-container" (click)="applyFilter($event)">
                    <button mat-mini-fab class="toolbar-item">
                        <mat-icon>search</mat-icon>
                    </button>
                    <div class="filters-toolbar-icon-text">Search</div>
                </div>
                <div class="filters-toolbar-button-container" (click)="clearFilters($event)">
                    <button mat-mini-fab class="toolbar-item">
                        <mat-icon>clear</mat-icon>
                    </button>
                    <div class="filters-toolbar-icon-text">Clear</div>
                </div>
            </div>
            <div [ngClass]="{'mat-hidden': isTab(4)}" class="products-filters-inputs" (scroll)="onScroll($event)">
                <mat-form-field *ngIf="!isTab(4)" #partNumberTooltip="matTooltip" floatLabel="auto"
                    appearance="standard" [ngClass]="{'active': isPartNumberFilterActive}"
                    [matTooltip]="(!isTab(3))? getTooltipMessage('partNumber', partNumberFilterElement) : autopopMsg1"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="shouldDisableTooltip('partNumber', partNumberFilterElement)"
                    (click)="partNumberTooltip.show(0)">
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <input matInput type="text" placeholder="Part Number" (keyup.enter)="applyFilter($event)"
                        [disabled]="(!isTab(3))? viewConfigs.filterInputs.partNumber.isDisabled : !getIsTableAutopop()"
                        #partNumberFilterElement />
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.partNumber.isDisabled : !getIsTableAutopop()">
                    </div>
                    <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
                </mat-form-field>

                <mat-form-field *ngIf="!isTab(4)" #manufacturerTooltip="matTooltip" floatLabel="auto"
                    appearance="standard" [ngClass]="{'active': isManufacturerFilterActive, 'mat-hidden': isTab(3)}"
                    [matTooltip]="(!isTab(3))? getTooltipMessage('manufacturer', manufacturerFilterElement) : autopopMsg2"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="shouldDisableTooltip('manufacturer', manufacturerFilterElement)"
                    (click)="manufacturerTooltip.show(0)">
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <input matInput type="text" placeholder="Manufacturer" (keyup.enter)="applyFilter($event)"
                        [disabled]="(!isTab(3))? viewConfigs.filterInputs.manufacturer.isDisabled : true"
                        #manufacturerFilterElement />
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.manufacturer.isDisabled : true"></div>
                    <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
                </mat-form-field>

                <mat-form-field #functionTooltip="matTooltip" floatLabel="auto" appearance="standard"
                    [ngClass]="{'active': isFunctionFilterActive, 'mat-hidden': isTab(3)}"
                    [matTooltip]="(!isTab(3))? getTooltipMessage('function', functionFilterElement) : autopopMsg2"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="shouldDisableTooltip('function', functionFilterElement)"
                    (click)="functionTooltip.show(0)">
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <input matInput type="text" placeholder="Function" (keyup.enter)="applyFilter($event)"
                        [disabled]="(!isTab(3))? viewConfigs.filterInputs.function.isDisabled : true"
                        #functionFilterElement />
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.function.isDisabled : true"></div>
                    <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
                </mat-form-field>

                <mat-form-field #technologyTooltip="matTooltip" floatLabel="auto" appearance="standard"
                    [ngClass]="{'active': isTechnologyFilterActive, 'mat-hidden': isTab(3)}"
                    [matTooltip]="(!isTab(3))? getTooltipMessage('technology', technologyFilterElement) : autopopMsg2"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="shouldDisableTooltip('technology', functionFilterElement)"
                    (click)="technologyTooltip.show(0)">
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <input matInput type="text" placeholder="Technology" (keyup.enter)="applyFilter($event)"
                        [disabled]="(!isTab(3))? viewConfigs.filterInputs.technology.isDisabled : true"
                        #technologyFilterElement />
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.technology.isDisabled : true"></div>
                    <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
                </mat-form-field>

                <mat-expansion-panel #selExpansionPanel #selSliderTooltip="matTooltip" disabled hideToggle
                    [ngClass]="{'active': selSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.sel.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="selSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>SEL/SEGR/SEB LET</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="selExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.sel.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.sel.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #selSlider [isDual]="true" [min]="slidersConfigs.sel.min" [max]="slidersConfigs.sel.max"
                        [unit]="slidersConfigs.sel.unit" [step]="slidersConfigs.sel.step"
                        [decimals]="slidersConfigs.sel.decimals" (dipFilterLowValueChange)="applyFilter($event)"
                        (dipFilterHiValueChange)="applyFilter($event)" class="sel-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-expansion-panel #tidExpansionPanel #tidSliderTooltip="matTooltip" disabled hideToggle
                    [ngClass]="{'active': tidSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.tid.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="tidSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>TID Hardness</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="tidExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.tid.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.tid.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #tidSlider [isDual]="true" [min]="slidersConfigs.tid.min" [max]="slidersConfigs.tid.max"
                        [unit]="slidersConfigs.tid.unit" [step]="slidersConfigs.tid.step"
                        [decimals]="slidersConfigs.tid.decimals" (dipFilterLowValueChange)="applyFilter($event)"
                        (dipFilterHiValueChange)="applyFilter($event)" class="tid-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-expansion-panel #ddFluenceExpansionPanel #ddFluenceSliderTooltip="matTooltip" disabled hideToggle
                    [ngClass]="{'active': ddFluenceSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.ddFluence.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="ddFluenceSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>DD Fluence Level</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="ddFluenceExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.ddFluence.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.ddFluence.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #ddFluenceSlider [min]="slidersConfigs.ddFluence.min"
                        [max]="slidersConfigs.ddFluence.max" [unit]="slidersConfigs.ddFluence.unit"
                        [inputPrefix]="slidersConfigs.ddFluence.inputPrefix" [step]="slidersConfigs.ddFluence.step"
                        [decimals]="slidersConfigs.ddFluence.decimals" (dipFilterLowValueChange)="applyFilter($event)"
                        class="dd-fluence-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-expansion-panel #criticalVoltageExpansionPanel #criticalVoltageSliderTooltip="matTooltip" disabled
                    hideToggle [ngClass]="{'active': criticalVoltageSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.criticalVoltage.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="criticalVoltageSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>Critical Voltage</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="criticalVoltageExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.criticalVoltage.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.criticalVoltage.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #criticalVoltageSlider [min]="slidersConfigs.criticalVoltage.min"
                        [max]="slidersConfigs.criticalVoltage.max" [unit]="slidersConfigs.criticalVoltage.unit"
                        [step]="slidersConfigs.criticalVoltage.step"
                        [decimals]="slidersConfigs.criticalVoltage.decimals"
                        (dipFilterLowValueChange)="applyFilter($event)" class="critical-voltage-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-form-field *ngIf="(!isTab(3))? isAdmin : false" #referenceTooltip="matTooltip" floatLabel="auto"
                    appearance="standard" [ngClass]="{'active': isReferenceFilterActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.reference.tooltipMessage : autopopMsg1"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="referenceTooltip.show(0)">
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <input matInput type="text" placeholder="Reference"
                        (keyup.enter)="applyFilter($event.target.value, 'referenceInput')"
                        [disabled]="(!isTab(3))? viewConfigs.filterInputs.reference.isDisabled : !getIsTableAutopop()"
                        #referenceFilterElement />
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.reference.isDisabled : !getIsTableAutopop()">
                    </div>
                    <mat-icon matSuffix (click)="applyFilter(referenceFilterElement.value, 'referenceInput')"
                        class="inline-search-icon">search</mat-icon>
                </mat-form-field>


                <mat-form-field #ionParticleTooltip="matTooltip" class="flex-row" floatLabel="auto"
                    appearance="standard" [ngClass]="{'active': isParticleFilterActive, 'mat-hidden': isTab(3)}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.ionParticle.tooltipMessage : autopopMsg2"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="(!isTab(3))? !viewConfigs.filterInputs.ionParticle.isDisabled : false"
                    (click)="ionParticleTooltip.show(0)" #ionParticle>
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <div class="multi-select-container">
                        <mat-select placeholder="ION" multiple (selectionChange)="applyFilter($event, 'selectIon')"
                            [disabled]="(!isTab(3))? viewConfigs.filterInputs.ionParticle.isDisabled : true"
                            #ionParticleFilterElement>
                            <mat-select-trigger class="multi-select-trigger">ION</mat-select-trigger>
                            <mat-option *ngFor="let client of particleSelectData" [value]="client.id">
                                {{client.clientName}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.ionParticle.isDisabled : true"></div>
                </mat-form-field>

                <mat-form-field #dataTypeTooltip="matTooltip" class="flex-row" floatLabel="auto" appearance="standard"
                    [ngClass]="{'active': isDataTypeFilterActive, 'mat-hidden': isTab(3)}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.dataType.tooltipMessage : autopopMsg2"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="(!isTab(3))? !viewConfigs.filterInputs.dataType.isDisabled : false"
                    (click)="dataTypeTooltip.show(0)" #dataType>
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <div class="multi-select-container">
                        <mat-select placeholder="Data Type" multiple
                            (selectionChange)="applyFilter($event, 'selectDataType')"
                            [disabled]="(!isTab(3))? viewConfigs.filterInputs.dataType.isDisabled : true"
                            #dataTypeFilterElement>
                            <mat-select-trigger class="multi-select-trigger">Data Type</mat-select-trigger>
                            <mat-option *ngFor="let client of dataTypeSelectData" [value]="client.id">
                                {{client.clientName}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.dataType.isDisabled : true"></div>
                </mat-form-field>

                <div class="products-csv-upload-container" *ngIf="(isTab(0))? isAdmin : false">
                    <input #productsFileInputElement id="productsFile" type="file" accept=".csv"
                        placeholder="Products File" hidden="true" (change)="onChangeProductsFileInputElement($event)" />
                    <mat-expansion-panel #productsFileExpansionPanel hideToggle disabled color="primary"
                        [expanded]="isProductsFileSelected">
                        <mat-expansion-panel-header (click)="productsFileInputElement.click()">
                            <mat-panel-title>
                                <mat-icon>label</mat-icon>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span *ngIf="!isProductsFileSelected">Select a File</span>
                                <span *ngIf="isProductsFileSelected">Select Another File</span>
                            </mat-panel-description>
                            <mat-icon class="dip-suffix-icon">more_horiz</mat-icon>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="isProductsFileSelected">
                            <strong>File:</strong> {{ productsFileInputElement.files[0].name }}<br />
                            <strong>Size:</strong> {{ productsFileInputElement.files[0].size }}<br />
                        </ng-container>
                        <mat-action-row>
                            <button mat-raised-button color="primary" (click)="onUploadProductsCsv($event)">Upload
                                File
                            </button>
                        </mat-action-row>
                    </mat-expansion-panel>
                </div>
                <div class="upload-file-div" *ngIf="isTab(3)">
                    <div *ngIf="isTab(3) && isStep (1)">
                        <button mat-button class="upload-file-btn"
                            matTooltip="Select the appropriate column with product&#13;names (manufacturer’s generic part numbers)"
                            [matTooltipShowDelay]="tooltipShowDelay" [matTooltipClass]="{'custom-tooltip': true}"
                            (click)="onChooseColumn()">
                            <mat-icon class="upload-file-icon">edit</mat-icon>
                            <p class="upload-file-tip">
                                Select Products
                            </p>
                        </button>
                    </div>
                    <div *ngIf="isTab(3) && isStep (2)">
                        <button mat-button class="upload-file-btn"
                            matTooltip="Execute the search engine, and fill out the&#13;spreadsheet with relevant data"
                            [matTooltipShowDelay]="tooltipShowDelay" [matTooltipClass]="{'custom-tooltip': true}"
                            (click)="onSearch()">
                            <mat-icon class="upload-file-icon">rocket_launch</mat-icon>
                            <p class="upload-file-tip">
                                Run Analysis
                            </p>
                        </button>
                    </div>
                    <div *ngIf="isTab(3) && isStep (3)">
                        <button mat-button class="upload-file-btn"
                            matTooltip="This will clear the table and any search&#13;results"
                            [matTooltipShowDelay]="tooltipShowDelay" [matTooltipClass]="{'custom-tooltip': true}"
                            (click)="onRestart()">
                            <mat-icon class="upload-file-icon">refresh</mat-icon>
                            <p class="upload-file-tip">
                                Start Over
                            </p>
                        </button>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'mat-hidden': !isTab(4)}" class="products-filters-inputs" (scroll)="onScroll($event)">

                <mat-form-field *ngIf="isTab(4)" #partNumberTooltip="matTooltip" floatLabel="auto" appearance="standard"
                    [ngClass]="{'active': isPartNumberFilterActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.partNumber.tooltipMessage : autopopMsg1"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="(!isTab(3))? !viewConfigs.filterInputs.partNumber.isDisabled : getIsTableAutopop()"
                    (click)="partNumberTooltip.show(0)">
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <input matInput type="text" placeholder="Part Number" (keyup.enter)="applyFilter($event)"
                        (click)="clearAutoComplete()"
                        [disabled]="(!isTab(3))? viewConfigs.filterInputs.partNumber.isDisabled : !getIsTableAutopop()"
                        [matAutocomplete]="auto" (input)="getAutoComplete($event, 'part_number')"
                        #partNumberFilterElement />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="clearAutoComplete()">
                        <mat-option *ngFor="let option of autoCompleteArray" [value]="option">{{option}}</mat-option>
                    </mat-autocomplete>
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.partNumber.isDisabled : !getIsTableAutopop()">
                    </div>
                    <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
                </mat-form-field>

                <mat-form-field #vendorPartNumberTooltip="matTooltip" floatLabel="auto" appearance="standard"
                    [ngClass]="{'active': isVendorPartNumberFilterActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.vendorPartNumber.tooltipMessage : autopopMsg1"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="(!isTab(3))? !viewConfigs.filterInputs.vendorPartNumber.isDisabled : getIsTableAutopop()"
                    (click)="vendorPartNumberTooltip.show(0)">
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <input matInput type="text" placeholder="Vendor Part Number" (keyup.enter)="applyFilter($event)"
                        (click)="clearAutoComplete()"
                        [disabled]="(!isTab(3))? viewConfigs.filterInputs.vendorPartNumber.isDisabled : !getIsTableAutopop()"
                        [matAutocomplete]="auto" (input)="getAutoComplete($event, 'vendor_part_number')"
                        #vendorPartNumberFilterElement />
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of autoCompleteArray" [value]="option">{{option}}</mat-option>
                    </mat-autocomplete>
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.vendorPartNumber.isDisabled : !getIsTableAutopop()">
                    </div>
                    <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
                </mat-form-field>

                <mat-form-field *ngIf="isTab(4)" #manufacturerTooltip="matTooltip" floatLabel="auto"
                    appearance="standard" [ngClass]="{'active': isManufacturerFilterActive, 'mat-hidden': isTab(3)}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.manufacturer.tooltipMessage : autopopMsg2"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="(!isTab(3))? !viewConfigs.filterInputs.manufacturer.isDisabled : false"
                    (click)="manufacturerTooltip.show(0)">
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <input matInput type="text" placeholder="Manufacturer" (keyup.enter)="applyFilter($event)"
                        [disabled]="(!isTab(3))? viewConfigs.filterInputs.manufacturer.isDisabled : true"
                        [matAutocomplete]="auto" (input)="getAutoComplete($event, 'manufacturer')"
                        #manufacturerFilterElement />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="clearAutoComplete()">
                        <mat-option *ngFor="let option of autoCompleteArray" [value]="option">{{option}}</mat-option>
                    </mat-autocomplete>
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.manufacturer.isDisabled : true"></div>
                    <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
                </mat-form-field>

                <mat-form-field #cageTooltip="matTooltip" floatLabel="auto" appearance="standard"
                    [ngClass]="{'active': isCageFilterActive, 'mat-hidden': true}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.cage.tooltipMessage : autopopMsg2"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="(!isTab(3))? !viewConfigs.filterInputs.cage.isDisabled : false"
                    (click)="cageTooltip.show(0)">
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <input matInput type="text" placeholder="Cage" (keyup.enter)="applyFilter($event)"
                        [disabled]="(!isTab(3))? viewConfigs.filterInputs.cage.isDisabled : true" #cageFilterElement />
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.cage.isDisabled : true"></div>
                    <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
                </mat-form-field>

                <mat-form-field #descriptionTooltip="matTooltip" floatLabel="auto" appearance="standard"
                    [ngClass]="{'active': isDescriptionFilterActive, 'mat-hidden': isTab(3)}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.description.tooltipMessage : autopopMsg2"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="(!isTab(3))? !viewConfigs.filterInputs.description.isDisabled : false"
                    (click)="descriptionTooltip.show(0)">
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <input matInput type="text" placeholder="Description" (keyup.enter)="applyFilter($event)"
                        [disabled]="(!isTab(3))? viewConfigs.filterInputs.description.isDisabled : true"
                        [matAutocomplete]="auto" (input)="getAutoComplete($event, 'description')"
                        #descriptionFilterElement />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="clearAutoComplete()">
                        <mat-option *ngFor="let option of autoCompleteArray" [value]="option">{{option}}</mat-option>
                    </mat-autocomplete>
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.description.isDisabled : true"></div>
                    <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
                </mat-form-field>

                <mat-form-field #dataTypeTooltipSMD="matTooltip" class="flex-row" floatLabel="auto"
                    appearance="standard" [ngClass]="{'active': isDataTypeFilterActive, 'mat-hidden': isTab(3)}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.dataType.tooltipMessage : autopopMsg2"
                    matTooltipShowDelay="5000"
                    [matTooltipDisabled]="(!isTab(3))? !viewConfigs.filterInputs.dataType.isDisabled : false"
                    (click)="dataTypeTooltipSMD.show(0)" #dataType>
                    <mat-icon matPrefix class="left-icon">label</mat-icon>
                    <div class="multi-select-container">
                        <mat-select placeholder="Data Type" multiple
                            (selectionChange)="applyFilter($event, 'selectDataType')"
                            [disabled]="(!isTab(3))? viewConfigs.filterInputs.dataType.isDisabled : true"
                            #smdDataTypeFilterElement>
                            <mat-select-trigger class="multi-select-trigger">Data Type</mat-select-trigger>
                            <mat-option *ngFor="let client of smdDataTypeSelectData" [value]="client.id">
                                {{client.clientName}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="disabled-input-click"
                        *ngIf="(!isTab(3))? viewConfigs.filterInputs.dataType.isDisabled : true"></div>
                </mat-form-field>

                <mat-expansion-panel #tidHdrExpansionPanel #tidHdrSliderTooltip="matTooltip" disabled hideToggle
                    [ngClass]="{'active': tidHdrSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.tidHdr.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="tidHdrSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>TID HDR</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="tidHdrExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.tidHdr.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.tidHdr.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #tidHdrSlider [min]="slidersConfigs.tidHdr.min" [max]="slidersConfigs.tidHdr.max"
                        [unit]="slidersConfigs.tidHdr.unit" [step]="slidersConfigs.tidHdr.step"
                        [decimals]="slidersConfigs.tidHdr.decimals" (dipFilterLowValueChange)="applyFilter($event)"
                        class="critical-voltage-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-expansion-panel #tidLdrExpansionPanel #tidLdrSliderTooltip="matTooltip" disabled hideToggle
                    [ngClass]="{'active': tidLdrSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.tidLdr.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="tidLdrSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>TID LDR</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="tidLdrExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.tidLdr.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.tidLdr.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #tidLdrSlider [min]="slidersConfigs.tidLdr.min" [max]="slidersConfigs.tidLdr.max"
                        [unit]="slidersConfigs.tidLdr.unit" [step]="slidersConfigs.tidLdr.step"
                        [decimals]="slidersConfigs.tidLdr.decimals" (dipFilterLowValueChange)="applyFilter($event)"
                        class="critical-voltage-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-expansion-panel #selSmdExpansionPanel #selSmdSliderTooltip="matTooltip" disabled hideToggle
                    [ngClass]="{'active': selSmdSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.selSmd.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="selSmdSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>SEL</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="selSmdExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.selSmd.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.selSmd.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #selSmdSlider [min]="slidersConfigs.selSmd.min" [max]="slidersConfigs.selSmd.max"
                        [unit]="slidersConfigs.selSmd.unit" [step]="slidersConfigs.selSmd.step"
                        [decimals]="slidersConfigs.selSmd.decimals" (dipFilterLowValueChange)="applyFilter($event)"
                        class="sel-filter-field">
                    </dip-slider>
                </mat-expansion-panel>


                <mat-expansion-panel #dseeExpansionPanel #dseeSliderTooltip="matTooltip" disabled hideToggle
                    [ngClass]="{'active': dseeSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.dsee.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="dseeSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>DSEE</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="dseeExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.dsee.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.dsee.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #dseeSlider [min]="slidersConfigs.dsee.min" [max]="slidersConfigs.dsee.max"
                        [unit]="slidersConfigs.dsee.unit" [step]="slidersConfigs.dsee.step"
                        [decimals]="slidersConfigs.dsee.decimals" (dipFilterLowValueChange)="applyFilter($event)"
                        class="critical-voltage-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-expansion-panel #seuExpansionPanel #seuSliderTooltip="matTooltip" disabled hideToggle
                    [ngClass]="{'active': seuSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.seu.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="seuSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>SEU</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="seuExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.seu.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.seu.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #seuSlider [min]="slidersConfigs.seu.min" [max]="slidersConfigs.seu.max"
                        [unit]="slidersConfigs.seu.unit" [step]="slidersConfigs.seu.step"
                        [decimals]="slidersConfigs.seu.decimals" (dipFilterLowValueChange)="applyFilter($event)"
                        class="critical-voltage-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-expansion-panel #neutronExpansionPanel #neutronSliderTooltip="matTooltip" disabled hideToggle
                    [ngClass]="{'active': neutronSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.neutron.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="neutronSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>Neutron</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="neutronExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.neutron.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.neutron.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #neutronSlider [min]="slidersConfigs.neutron.min" [max]="slidersConfigs.neutron.max"
                        [unit]="slidersConfigs.neutron.unit" [inputPrefix]="slidersConfigs.neutron.inputPrefix"
                        [step]="slidersConfigs.neutron.step" [decimals]="slidersConfigs.neutron.decimals"
                        (dipFilterLowValueChange)="applyFilter($event)" class="dd-fluence-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-expansion-panel #doseRateLatchupExpansionPanel #doseRateLatchupSliderTooltip="matTooltip" disabled
                    hideToggle [ngClass]="{'active': doseRateLatchupSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.doseRateLatchup.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="doseRateLatchupSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>Dose Rate Latchup</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="doseRateLatchupExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.doseRateLatchup.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.doseRateLatchup.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #doseRateLatchupSlider [min]="slidersConfigs.doseRateLatchup.min"
                        [max]="slidersConfigs.doseRateLatchup.max" [unit]="slidersConfigs.doseRateLatchup.unit"
                        [inputPrefix]="slidersConfigs.doseRateLatchup.inputPrefix"
                        [step]="slidersConfigs.doseRateLatchup.step"
                        [decimals]="slidersConfigs.doseRateLatchup.decimals"
                        (dipFilterLowValueChange)="applyFilter($event)" class="dd-fluence-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-expansion-panel #doseRateUpsetExpansionPanel #doseRateUpsetSliderTooltip="matTooltip" disabled
                    hideToggle [ngClass]="{'active': doseRateUpsetSlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.doseRateUpset.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="doseRateUpsetSliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>Dose Rate Upset</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="doseRateUpsetExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.doseRateUpset.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.doseRateUpset.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #doseRateUpsetSlider [min]="slidersConfigs.doseRateUpset.min"
                        [max]="slidersConfigs.doseRateUpset.max" [unit]="slidersConfigs.doseRateUpset.unit"
                        [inputPrefix]="slidersConfigs.doseRateUpset.inputPrefix"
                        [step]="slidersConfigs.doseRateUpset.step" [decimals]="slidersConfigs.doseRateUpset.decimals"
                        (dipFilterLowValueChange)="applyFilter($event)" class="dd-fluence-filter-field">
                    </dip-slider>
                </mat-expansion-panel>

                <mat-expansion-panel #doseRateSurvivabilityExpansionPanel
                    #doseRateSurvivabilitySliderTooltip="matTooltip" disabled hideToggle
                    [ngClass]="{'active': doseRateSurvivabilitySlider.isActive}"
                    [matTooltip]="(!isTab(3))? viewConfigs.filterInputs.doseRateSurvivability.tooltipMessage : autopopMsg1"
                    [matTooltipShowDelay]="5000"
                    [matTooltipDisabled]="(!isTab(3))? hasConditionalColumns : getIsTableAutopop()"
                    (click)="doseRateSurvivabilitySliderTooltip.show(0)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>label</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>Dose Rate Survivability</mat-panel-description>
                        <mat-icon class="dip-suffix-icon" (click)="doseRateSurvivabilityExpansionPanel.toggle()"
                            *ngIf="(!isTab(3))? !viewConfigs.filterInputs.doseRateSurvivability.isDisabled : getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                        <mat-icon class="dip-suffix-icon"
                            *ngIf="(!isTab(3))? viewConfigs.filterInputs.doseRateSurvivability.isDisabled : !getIsTableAutopop()">
                            more_horiz
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <dip-slider #doseRateSurvivabilitySlider [min]="slidersConfigs.doseRateSurvivability.min"
                        [max]="slidersConfigs.doseRateSurvivability.max"
                        [unit]="slidersConfigs.doseRateSurvivability.unit"
                        [inputPrefix]="slidersConfigs.doseRateSurvivability.inputPrefix"
                        [step]="slidersConfigs.doseRateSurvivability.step"
                        [decimals]="slidersConfigs.doseRateSurvivability.decimals"
                        (dipFilterLowValueChange)="applyFilter($event)" class="dd-fluence-filter-field">
                    </dip-slider>
                </mat-expansion-panel>


                <div class="products-csv-upload-container" *ngIf="(isTab(4))? isAdmin : false">
                    <input #smdProductsFileInputElement id="smdProductsFile" type="file" accept=".csv"
                        placeholder="SMD Products File" hidden="true"
                        (change)="onChangeSmdProductsFileInputElement($event)" />
                    <mat-expansion-panel #productsFileExpansionPanel hideToggle disabled color="primary"
                        [expanded]="isSmdProductsFileSelected">
                        <mat-expansion-panel-header (click)="smdProductsFileInputElement.click()">
                            <mat-panel-title>
                                <mat-icon>label</mat-icon>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span *ngIf="!isSmdProductsFileSelected">Select a SMD File</span>
                                <span *ngIf="isSmdProductsFileSelected">Select Another File</span>
                            </mat-panel-description>
                            <mat-icon class="dip-suffix-icon">more_horiz</mat-icon>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="isSmdProductsFileSelected">
                            <strong>File:</strong> {{ smdProductsFileInputElement.files[0].name }}<br />
                            <strong>Size:</strong> {{ smdProductsFileInputElement.files[0].size }}<br />
                        </ng-container>
                        <mat-action-row style="padding: 16px 8px 16px 5px;">
                            <button mat-raised-button color="primary"
                                (click)="onUploadProductsCsv($event, 'smd', 'append')">Append
                                File
                            </button>
                            <button mat-raised-button color="primary"
                                (click)="onUploadProductsCsv($event, 'smd')">Upload
                                File
                            </button>
                        </mat-action-row>
                    </mat-expansion-panel>
                </div>
            </div>

            <div class="products-sidenav-footer">&copy; Zero-G Radiation Assurance</div>
        </div>

        <app-part-requests-filter [class.cdk-visually-hidden]="!isTab(2)"></app-part-requests-filter>

    </mat-sidenav>

    <mat-sidenav mode="side" position="end" opened [fixedInViewport]="false" [fixedTopGap]="0" [fixedBottomGap]="0"
        [disableClose]="true" class="products-sidenav products-right-sidenav"
        [ngClass]="{'products-sidenav-minimized': !isRightSidenavExpanded}">
        <div class="products-right-sidenav-wrapper">
            <div class="products-right-sidenav-toolbar">
                <button mat-icon-button matTooltip="Saved" [matTooltipShowDelay]="tooltipShowDelay"
                    (click)="onSavedListIconClick($event)" class="toolbar-item saved-list-icon"
                    [class.active]="isSavedListIconActive">
                    <mat-icon>work</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Multiple search" [matTooltipShowDelay]="tooltipShowDelay"
                    (click)="onMultipleSearchIconClick($event)" class="toolbar-item multiple-search-icon"
                    [class.active]="isMultipleSearchIconActive">
                    <mat-icon>queue</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Display/hide search menu" [matTooltipShowDelay]="tooltipShowDelay"
                    (click)="sidenav.toggle()" class=" toolbar-item multiple-search-icon">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>

            <div class="products-right-sidenav-content">
                <mat-card class="saved-results-card" *ngIf="isSavedListIconActive">
                    <div class="saved-results-card-header">
                        <div class="saved-results-card-title">Saved Results</div>
                        <div class="saved-results-card-close">
                            <button mat-icon-button (click)="clearSavedList()" class="results-clear-icon">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                    <mat-card-content>
                        <ng-container *ngFor="let product of savedProducts">
                            <button mat-button (click)="setSavedListFilterByPartNumber(product.part_number)"
                                class="saved-result-button">
                                {{ product.part_number }}
                            </button>
                        </ng-container>
                    </mat-card-content>
                </mat-card>

                <mat-card class="multiple-search-card" *ngIf="isMultipleSearchIconActive">
                    <textarea #multipleSearchTextarea placeholder="Enter part names (e.g. AD590, LM124, etc.)"
                        cdkTextareaAutosize [disabled]="viewConfigs.filterInputs.partNumber.isDisabled"></textarea>
                    <div class="multiple-search-card-actions">
                        <button mat-button (click)="onSubmitMultipleSearch()"
                            [disabled]="viewConfigs.filterInputs.partNumber.isDisabled">
                            Submit
                        </button>
                        <button mat-button (click)="onClearMultipleSearch()"
                            [disabled]="viewConfigs.filterInputs.partNumber.isDisabled">
                            Clear
                        </button>
                    </div>
                </mat-card>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-tab-group mat-align-tabs="start" mat-stretch-tabs animationDuration="0ms"
            [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="onSelectedTabIndexChange($event)"
            class="products-tab-group">
            <mat-tab label="Database">
                <div class="products-tab-content-container">
                    <div class="products-table-container">
                        <app-dip-tabulator #tabulatorTable (showmore)="showMoreResult($event)"></app-dip-tabulator>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Saved">
                <div #savedProductsTableContainer class="products-tab-content-container">
                    <div class="products-table-container">
                        <app-dip-tabulator #savedProductsTable (showmore)="showMoreResult($event)"></app-dip-tabulator>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Need data">
                <app-part-requests></app-part-requests>
            </mat-tab>
            <mat-tab label="Auto BOM scrub">
                <app-upload-file #UploadFileComponent (refEmitter)="onRefClicked($event)"></app-upload-file>
            </mat-tab>
            <mat-tab label="Space and Military Grade">
                <div #smdProductsTableContainer class="products-tab-content-container">
                    <div class="products-table-container">
                        <app-dip-tabulator #smdProductsTable (showmore)="showMoreResult($event)"></app-dip-tabulator>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Space and Military Saved">
                <div #savedSMDProductsTableContainer class="products-tab-content-container">
                    <div class="products-table-container">
                        <app-dip-tabulator #savedSMDProductsTable
                            (showmore)="showMoreResult($event)"></app-dip-tabulator>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-sidenav-content>


</mat-sidenav-container>

<div class="context-menu-label-hide-container">
    <button #contextMenuLabelAddElem mat-icon-button matTooltip="Save for later"
        [matTooltipShowDelay]="tooltipShowDelay">
        <mat-icon>add</mat-icon>
    </button>
    <button #contextMenuLabelAddDisabledElem mat-icon-button disabled matTooltip="Already saved"
        [matTooltipShowDelay]="tooltipShowDelay">
        <mat-icon>add</mat-icon>
    </button>
    <button #contextMenuLabelMoreElem mat-icon-button matTooltip="More details"
        [matTooltipShowDelay]="tooltipShowDelay">
        <mat-icon>unfold_more</mat-icon>
    </button>
</div>

<ng-template #errorDialog let-data>
    <div mat-dialog-title>Error</div>
    <div mat-dialog-content>
        <p class="error-message">
            <ng-container *ngFor="let msg of data.messages">
                {{ msg }}<br />
            </ng-container>
        </p>
    </div>
    <mat-dialog-actions align="end">
        <button mat-button mat-flat-button mat-dialog-close color="primary">Ok</button>
    </mat-dialog-actions>
</ng-template>
